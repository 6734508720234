import React from "react";
import { Link } from "react-router-dom";
import { useWindowWidth } from "../../breakpoints";
import "./style.css";

export const LokallyAi = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="lokally-AI">
      <div
        className="lokally-AI-mob"
        style={{
          height: screenWidth < 1920 ? "3381px" : screenWidth >= 1920 ? "6033px" : undefined,
          position: screenWidth < 1920 ? "relative" : undefined,
          width: screenWidth < 1920 ? "374px" : screenWidth >= 1920 ? "1920px" : undefined,
        }}
      >
        <div
          className="overlap-92"
          style={{
            height: screenWidth >= 1920 ? "6357px" : screenWidth < 1920 ? "3355px" : undefined,
            left: screenWidth >= 1920 ? "-194px" : screenWidth < 1920 ? "-128px" : undefined,
            position: screenWidth >= 1920 ? "relative" : screenWidth < 1920 ? "absolute" : undefined,
            top: screenWidth >= 1920 ? "-408px" : screenWidth < 1920 ? "134px" : undefined,
            width: screenWidth >= 1920 ? "2306px" : screenWidth < 1920 ? "591px" : undefined,
          }}
        >
          <div
            className="frame-50"
            style={{
              height: screenWidth < 1920 ? "346px" : screenWidth >= 1920 ? "6357px" : undefined,
              left: screenWidth < 1920 ? "158px" : screenWidth >= 1920 ? "0" : undefined,
              top: screenWidth < 1920 ? "818px" : screenWidth >= 1920 ? "0" : undefined,
              width: screenWidth < 1920 ? "314px" : screenWidth >= 1920 ? "2306px" : undefined,
            }}
          >
            {screenWidth < 1920 && (
              <div className="frame-51">
                <div className="frame-52">
                  <div className="how-it-works-wrapper">
                    <p className="how-it-works">
                      <span className="text-wrapper-63">How It </span>
                      <span className="text-wrapper-64">works.</span>
                    </p>
                  </div>
                </div>
                <p className="lokally-AI-helps-you">
                  <span className="text-wrapper-65">Lokally AI</span>
                  <span className="text-wrapper-66">
                    {" "}
                    helps you unlock the secrets of every place Making every step of your journey exciting.
                  </span>
                </p>
                <img className="article-wie" alt="Article wie" src="/img/article-wie-2-2x.png" />
              </div>
            )}

            {screenWidth >= 1920 && (
              <>
                <div className="group-20">
                  <div className="ellipse-22" />
                  <div className="ellipse-23" />
                  <div className="ellipse-24" />
                </div>
                <div className="group-21">
                  <img className="decor-26" alt="Decor" src="/img/decor-15.svg" />
                  <div className="overlap-group-18">
                    <img className="decor-27" alt="Decor" src="/img/decor-16.svg" />
                    <img className="decor-28" alt="Decor" src="/img/decor-19.svg" />
                  </div>
                  <img className="decor-29" alt="Decor" src="/img/decor-17.svg" />
                  <div className="ellipse-25" />
                  <img className="decor-30" alt="Decor" src="/img/decor-18.svg" />
                  <div className="group-22">
                    <div className="ellipse-26" />
                    <div className="ellipse-27" />
                    <div className="ellipse-28" />
                  </div>
                </div>
                <div className="group-23" />
                <div className="ellipse-29" />
                <div className="text-wrapper-67">Home</div>
                <div className="text-wrapper-68">Boutique Holidays</div>
                <div className="text-wrapper-69">Lokally AI</div>
                <div className="text-wrapper-70">About Us</div>
                <div className="frame-53">
                  <div className="intro">
                    <div className="healine">
                      <div className="text">
                        <p className="meet-lokally-AI">
                          <span className="text-wrapper-71">Meet</span>
                          <span className="text-wrapper-72">&nbsp;</span>
                          <span className="text-wrapper-73">Lokally AI</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="text-wrapper-74">Your Smart Travel Companion</div>
                </div>
                <div className="article-wie-2">
                  <div className="background" />
                  <p className="heading-the-way-to">The Way to Worry-Free Journey</p>
                  <p className="text-wrapper-75">
                    to worry about little things like Destination Research, What to pack, Itinerary planning, Local
                    Cuisine, Local Culture,
                  </p>
                </div>
                <div className="container">
                  <div className="group-24">
                    <div className="background-shadow">
                      <img className="img-2" alt="Sunbathing svgrepo" src="/img/sunbathing-svgrepo-com-1-2-2.svg" />
                      <div className="frame-54">
                        <p className="text-wrapper-76">
                          Receive personalized recommendations based on your location and travel style,
                        </p>
                        <div className="text-wrapper-77">Personal Notification</div>
                      </div>
                    </div>
                    <div className="background-shadow-2">
                      <img className="img-2" alt="Suitcase svgrepo com" src="/img/suitcase-svgrepo-com-1-3.svg" />
                      <div className="frame-54">
                        <div className="text-wrapper-77">Local Insights</div>
                        <p className="text-wrapper-76">
                          Receive personalized recommendations based on your location and travel style,
                        </p>
                      </div>
                    </div>
                    <div className="background-shadow-3">
                      <img className="img-2" alt="Cocktail svgrepo com" src="/img/cocktail-svgrepo-com-1-3-2.svg" />
                      <div className="frame-55">
                        <div className="text-wrapper-77">Interactive Experience</div>
                        <p className="text-wrapper-76">
                          Receive personalized recommendations based on your location and travel style,
                        </p>
                      </div>
                    </div>
                    <div className="background-shadow-4">
                      <img className="img-2" alt="Indicator svgrepo" src="/img/indicator-svgrepo-com-1-3-2.svg" />
                      <div className="frame-55">
                        <div className="text-wrapper-77">Expert Guidance</div>
                        <p className="text-wrapper-76">
                          Receive personalized recommendations based on your location and travel style,
                        </p>
                      </div>
                    </div>
                    <div className="background-shadow-5">
                      <div className="heading-digital">Digital Convenience</div>
                      <img
                        className="indicator-svgrepo"
                        alt="Indicator svgrepo"
                        src="/img/indicator-svgrepo-com-1-4.svg"
                      />
                      <p className="text-wrapper-78">
                        Receive personalized recommendations based on your location and travel style, recommendations
                        based on your location and travel style,
                      </p>
                    </div>
                  </div>
                  <p className="why-choose-lokally">
                    <span className="text-wrapper-79">Why Choose </span>
                    <span className="text-wrapper-80">Lokally AI.&nbsp;&nbsp;</span>
                  </p>
                </div>
                <div className="group-25">
                  <img className="article-wie-3" alt="Article wie" src="/img/article-wie-2.png" />
                  <div className="heading">
                    <div className="text-wrapper-81">How It works.</div>
                  </div>
                  <div className="lokally-AI-helps-you-wrapper">
                    <p className="lokally-AI-helps-you-2">
                      <span className="text-wrapper-82">Lokally AI</span>
                      <span className="text-wrapper-83">&nbsp;</span>
                      <span className="text-wrapper-84">
                        helps you unlock the secrets of every place Making every step of your journey exciting.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="we-are-in-wrapper">
                  <p className="we-are-in">
                    We are in developement We are in developement We are in developement developement We are in
                    developement thankyou,&nbsp;&nbsp;We are in developement thankyou We are in developement thankyou
                  </p>
                </div>
                <div className="row-6">
                  <div className="overlap-39">
                    <div className="frame-56">
                      <div className="text-wrapper-85">All Rights Reserverd@goEssy</div>
                      <div className="list-5">
                        <div className="link-item-11">
                          <div className="symbol-13"></div>
                        </div>
                        <div className="link-item-12">
                          <div className="symbol-14"></div>
                        </div>
                        <div className="link-item-13">
                          <div className="symbol-15"></div>
                        </div>
                      </div>
                      <img
                        className="goessy-black-logo-5"
                        alt="Goessy black logo"
                        src="/img/goessy-black-logo-final-1-2-3.svg"
                      />
                    </div>
                    <div className="frame-57">
                      <div className="overlap-40">
                        <Link className="text-wrapper-86" to="/home-page">
                          Home
                        </Link>
                        <img className="line-12" alt="Line" src="/img/line-218-3.svg" />
                      </div>
                      <div className="overlap-41">
                        <div className="text-wrapper-87">Boutique Holidays</div>
                        <img className="line-12" alt="Line" src="/img/line-218-3.svg" />
                      </div>
                      <div className="overlap-group-19">
                        <div className="text-wrapper-87">Lokally AI</div>
                        <img className="line-12" alt="Line" src="/img/line-218-3.svg" />
                      </div>
                      <div className="overlap-42">
                        <Link className="text-wrapper-86" to="/about-us-page">
                          About Us
                        </Link>
                        <img className="line-13" alt="Line" src="/img/line-218-3.svg" />
                      </div>
                      <div className="overlap-43">
                        <Link className="text-wrapper-86" to="/blog-page">
                          Blog
                        </Link>
                        <img className="line-14" alt="Line" src="/img/line-218-3.svg" />
                      </div>
                      <div className="overlap-44">
                        <div className="text-wrapper-87">Terms and Policies</div>
                        <img className="line-13" alt="Line" src="/img/line-218-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="group-26">
                  <div className="frame-58">
                    <p className="text-wrapper-88">
                      Are You Ready To Experience The Future Of Travel? Download goEssy App Now.
                    </p>
                  </div>
                  <img className="frame-59" alt="Frame" src="/img/frame-1686557547-2.svg" />
                </div>
              </>
            )}
          </div>
          {screenWidth >= 1920 && (
            <Link className="frame-60" to="/blog-page">
              <div className="text-wrapper-89">Blogs</div>
            </Link>
          )}

          {screenWidth < 1920 && (
            <>
              <div className="frame-61">
                <p className="text-wrapper-90">
                  We are in developement We are in developement We are in developement developement We are in
                  developement thankyou
                </p>
              </div>
              <div className="frame-62">
                <div className="frame-63">
                  <div className="background-shadow-6">
                    <img className="img-3" alt="Suitcase svgrepo com" src="/img/suitcase-svgrepo-com-1-3-2.svg" />
                    <div className="frame-64">
                      <div className="text-wrapper-91">Local Insights</div>
                      <p className="text-wrapper-92">
                        Receive personalized recommendations based on your location and travel style,
                      </p>
                    </div>
                  </div>
                  <div className="background-shadow-7">
                    <img className="img-3" alt="Indicator svgrepo" src="/img/indicator-svgrepo-com-1-3.svg" />
                    <div className="frame-65">
                      <div className="text-wrapper-91">Expert Guidance</div>
                      <p className="text-wrapper-92">
                        Receive personalized recommendationsbased on&nbsp;&nbsp;your location and travel style,
                      </p>
                    </div>
                  </div>
                  <div className="background-shadow-8">
                    <img className="img-3" alt="Sunbathing svgrepo" src="/img/sunbathing-svgrepo-com-1-2.svg" />
                    <div className="frame-66">
                      <p className="text-wrapper-92">
                        Receive personalized recommendations based on your location and travel style,
                      </p>
                      <div className="text-wrapper-91">Personal Notification</div>
                    </div>
                  </div>
                  <div className="background-shadow-9">
                    <img className="img-3" alt="Cocktail svgrepo com" src="/img/cocktail-svgrepo-com-1-3.svg" />
                    <div className="frame-67">
                      <div className="text-wrapper-91">Interactive Experience</div>
                      <p className="text-wrapper-92">
                        Receive personalized recommendations based on your location and travel style,
                      </p>
                    </div>
                  </div>
                  <div className="background-shadow-10">
                    <img className="img-3" alt="Cocktail svgrepo com" src="/img/cocktail-svgrepo-com-1-3-1.svg" />
                    <div className="frame-68">
                      <div className="text-wrapper-91">Digital Convenience</div>
                      <p className="text-wrapper-92">
                        Receive personalized recommendations based on your location and travel style,
                      </p>
                    </div>
                  </div>
                </div>
                <p className="why-choose-lokally-2">
                  <span className="text-wrapper-79">Why Choose </span>
                  <span className="text-wrapper-80">Lokally AI.</span>
                  <span className="text-wrapper-93">&nbsp;</span>
                  <span className="text-wrapper-79">&nbsp;</span>
                </p>
              </div>
              <div className="article-wie-4">
                <div className="background-2" />
                <div className="frame-69">
                  <p className="heading-the-way-to-2">The Way to Worry-Free Journey</p>
                  <p className="text-wrapper-94">
                    to worry about little things like Destination Research, What to pack, Itinerary planning, Local
                    Cuisine, Local Culture,
                  </p>
                </div>
              </div>
            </>
          )}

          <img
            className="goessy-logo-pdf-6"
            style={{
              height: screenWidth >= 1920 ? "60px" : screenWidth < 1920 ? "274px" : undefined,
              left: screenWidth >= 1920 ? "288px" : screenWidth < 1920 ? "128px" : undefined,
              top: screenWidth >= 1920 ? "446px" : screenWidth < 1920 ? "11px" : undefined,
              width: screenWidth >= 1920 ? "106px" : screenWidth < 1920 ? "92px" : undefined,
            }}
            alt="Goessy logo pdf"
            src={
              screenWidth >= 1920 ? "/img/goessy-logo-pdf.png" : screenWidth < 1920 ? "/img/decor-22-2.svg" : undefined
            }
          />
          {screenWidth < 1920 && (
            <>
              <div className="group-27">
                <img className="decor-31" alt="Decor" src="/img/decor-23.svg" />
                <img className="decor-32" alt="Decor" src="/img/decor-24.svg" />
                <div className="ellipse-30" />
                <div className="overlap-45">
                  <img className="decor-33" alt="Decor" src="/img/decor-25-2.svg" />
                  <img className="decor-34" alt="Decor" src="/img/decor-26-2.svg" />
                </div>
                <div className="group-28">
                  <div className="ellipse-31" />
                  <div className="ellipse-32" />
                  <div className="overlap-group-20">
                    <div className="ellipse-33" />
                    <div className="ellipse-34" />
                  </div>
                </div>
              </div>
              <div className="frame-70">
                <div className="overlap-46">
                  <div className="item-13">
                    <img
                      className="goessy-black-logo-6"
                      alt="Goessy black logo"
                      src="/img/goessy-black-logo-final-1-2-3-2.svg"
                    />
                    <div className="text-wrapper-95">All Rights Reserverd@goEssy</div>
                    <div className="list-6">
                      <div className="link-item-14">
                        <div className="symbol-16"></div>
                      </div>
                      <div className="link-item-15">
                        <div className="symbol-17"></div>
                      </div>
                      <div className="link-item-16">
                        <div className="symbol-18"></div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-71">
                    <div className="frame-72">
                      <div className="frame-73">
                        <div className="overlap-group-21">
                          <div className="text-wrapper-96">Home</div>
                          <img className="line-15" alt="Line" src="/img/line-218-3-2.svg" />
                        </div>
                      </div>
                      <div className="frame-74">
                        <div className="overlap-47">
                          <div className="text-wrapper-96">Boutique Holidays</div>
                          <img className="line-15" alt="Line" src="/img/line-219-3.svg" />
                        </div>
                      </div>
                      <div className="frame-75">
                        <div className="overlap-48">
                          <div className="text-wrapper-96">Lokally AI</div>
                          <img className="line-15" alt="Line" src="/img/line-220-3.svg" />
                        </div>
                      </div>
                    </div>
                    <div className="frame-76">
                      <div className="frame-77">
                        <div className="overlap-group-22">
                          <div className="text-wrapper-96">About Us</div>
                          <img className="line-16" alt="Line" src="/img/line-221-3.svg" />
                        </div>
                      </div>
                      <div className="frame-78">
                        <div className="overlap-49">
                          <div className="text-wrapper-96">Blog</div>
                          <img className="line-15" alt="Line" src="/img/line-222-3.svg" />
                        </div>
                      </div>
                      <div className="frame-79">
                        <div className="overlap-50">
                          <div className="text-wrapper-96">Terms and Policies</div>
                          <img className="line-16" alt="Line" src="/img/line-223-3.svg" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-80">
                <div className="frame-81">
                  <p className="text-wrapper-97">
                    Are You Ready To Experience The Future Of Travel? Download goEssy App Now.
                  </p>
                </div>
                <img className="frame-82" alt="Frame" src="/img/frame-1686557547.svg" />
              </div>
              <div className="group-29">
                <p className="meet-lokallyai">
                  <span className="text-wrapper-98">Meet </span>
                  <span className="text-wrapper-99">LokallyAI.</span>
                </p>
                <div className="text-wrapper-100">Your Smart Travel Companion</div>
              </div>
            </>
          )}
        </div>
        {screenWidth < 1920 && (
          <>
            <div className="group-30">
              <div className="ellipse-22" />
              <div className="ellipse-23" />
              <div className="ellipse-24" />
            </div>
            <div className="navbar-3">
              <a
                className="link-home-3"
                href="https://www.figma.com/design/TwClzvPbm6dQLNGBhf7ycp?node-id=2-2"
                rel="noopener noreferrer"
                target="_blank"
              >
                Home
              </a>
              <div className="link-our-3">Our Destinations</div>
              <a
                className="link-lokally-AI-3"
                href="https://www.figma.com/design/TwClzvPbm6dQLNGBhf7ycp?node-id=122-321"
                rel="noopener noreferrer"
                target="_blank"
              >
                Lokally AI
              </a>
              <div className="link-business-with-3">Business with us</div>
              <a
                className="link-about-us-3"
                href="https://www.figma.com/design/TwClzvPbm6dQLNGBhf7ycp?node-id=122-1271"
                rel="noopener noreferrer"
                target="_blank"
              >
                About Us
              </a>
              <div className="frame-83">
                <div className="overlap-group-23">
                  <div className="rectangle-8" />
                  <div className="text-wrapper-101">Menu</div>
                  <img className="arrow-3" alt="Arrow" src="/img/arrow-1.svg" />
                </div>
              </div>
              <img className="goessy-logo-pdf-7" alt="Goessy logo pdf" src="/img/goessy-logo-pdf.png" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
