import React from "react";
import { useWindowWidth } from "../../breakpoints";
import "./style.css";

export const TermsAndConditions = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="terms-and-conditions">
      <div
        className="div"
        style={{
          height: screenWidth < 1920 ? "3021px" : screenWidth >= 1920 ? "2541px" : undefined,
          overflow: screenWidth >= 1920 ? "hidden" : undefined,
          width: screenWidth < 1920 ? "375px" : screenWidth >= 1920 ? "1920px" : undefined,
        }}
      >
        {screenWidth >= 1920 && (
          <>
            <img className="decor" alt="Decor" src="/img/decor-2.png" />
            <img className="img" alt="Decor" src="/img/decor-2.png" />
            <div className="ellipse" />
            <div className="overlap">
              <img className="goessy-logo-pdf" alt="Goessy logo pdf" src="/img/goessy-logo-pdf.png" />
              <div className="text-wrapper">Home</div>
              <div className="text-wrapper-2">Boutique Holidays</div>
              <div className="text-wrapper-3">Lokally AI</div>
              <div className="text-wrapper-4">About Us</div>
              <div className="frame">
                <div className="overlap-group">
                  <div className="overlap-group-2">
                    <img className="decor-2" alt="Decor" src="/img/decor-2.svg" />
                    <div className="group" />
                    <img className="decor-3" alt="Decor" src="/img/decor-3.svg" />
                    <img className="decor-4" alt="Decor" src="/img/decor-4.svg" />
                    <div className="group-2">
                      <div className="ellipse-2" />
                      <div className="ellipse-3" />
                      <div className="ellipse-4" />
                    </div>
                  </div>
                  <div className="group-3">
                    <div className="ellipse-2" />
                    <div className="ellipse-3" />
                    <div className="ellipse-4" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="overlap-2">
                  <div className="frame-2">
                    <div className="text-wrapper-5">All Rights Reserverd@goEssy</div>
                    <div className="list">
                      <div className="link-item">
                        <div className="symbol"></div>
                      </div>
                      <div className="symbol-wrapper">
                        <div className="symbol-2"></div>
                      </div>
                      <div className="div-wrapper">
                        <div className="symbol-3"></div>
                      </div>
                    </div>
                    <img
                      className="goessy-black-logo"
                      alt="Goessy black logo"
                      src="/img/goessy-black-logo-final-1-2.svg"
                    />
                  </div>
                  <div className="frame-3">
                    <div className="overlap-3">
                      <div className="text-wrapper-6">Home</div>
                      <img className="line" alt="Line" src="/img/line-218.svg" />
                    </div>
                    <div className="overlap-4">
                      <div className="text-wrapper-6">Boutique Holidays</div>
                      <img className="line" alt="Line" src="/img/line-218.svg" />
                    </div>
                    <div className="overlap-group-3">
                      <div className="text-wrapper-6">Lokally AI</div>
                      <img className="line" alt="Line" src="/img/line-218.svg" />
                    </div>
                    <div className="overlap-5">
                      <div className="text-wrapper-6">About Us</div>
                      <img className="line-2" alt="Line" src="/img/line-218.svg" />
                    </div>
                    <div className="overlap-6">
                      <div className="text-wrapper-6">Blog</div>
                      <img className="line-3" alt="Line" src="/img/line-218.svg" />
                    </div>
                    <div className="overlap-7">
                      <div className="text-wrapper-6">Terms and Policies</div>
                      <img className="line-2" alt="Line" src="/img/line-218.svg" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-4">
                <div className="text-wrapper-7">Blogs</div>
              </div>
              <div className="privacy-policies-wrapper">
                <p className="privacy-policies">
                  Privacy Policies
                  <br />
                  <br />
                  1. Data Collection: We collect information, including but not limited to names, emails, and locations,
                  to enhance user experiences and provide personalized services.
                  <br />
                   2. Data Usage: Collected data may be used for internal purposes, such as personalization and
                  communication with users.
                  <br />
                   3. Data Security: We employ industry-standard security measures to protect user information from
                  unauthorized access. 
                  <br />
                   ---
                  <br />
                  Shipping Policy 
                  <br />
                   <br />
                  1. Delivery Time: Estimated delivery times vary based on user locations and travel plans. 
                  <br />
                  2. Shipping Fees: All applicable shipping fees are communicated clearly before purchase. 
                  <br />
                  3. Returns: Refer to our Refund Policy for information on returns.
                  <br />
                   ---
                  <br />
                   Data Security Policy:
                  <br />
                   1. Data Protection: We use industry-standard security measures to safeguard user information. 
                  <br />
                  2. Data Sharing: User information is not sold to third parties. 
                  <br />
                  --- 
                  <br />
                  Refund Policy
                  <br />
                   1. Eligibility: Refunds may be available for specific products or services, subject to conditions. 
                  <br />
                  2. Refund Process: Refund requests must adhere to specific guidelines and timeframes outlined in our
                  Refund Policy.
                  <br />
                   ---
                  <br />
                   Wallet Policy:
                  <br />
                   1. Wallet Use: Users can utilize wallet balances for service payments, including bookings and
                  purchases. 
                  <br />
                  2. No Deposits: Direct deposits into the wallet are not allowed. Wallet balances are generated through
                  refunds after bank transfers.
                  <br />
                   3. Refunds to Wallet: Refunded amounts for services or purchases will be credited to the user&#39;s
                  wallet balance. 
                  <br />
                  4. Transferring to Bank: Users can transfer funds from their wallets to their bank account at any
                  time. 
                  <br />
                  --- 
                  <br />
                  Cancellation Policy
                  <br />
                   <br />
                   1. Cancellation Process: To cancel bookings or services, follow these steps: 
                  <br />
                  Log in to your account.
                  <br />
                   Navigate to the &#34;Bookings&#34; or &#34;Services&#34; section. - Select the booking or service you
                  wish to cancel.
                  <br />
                   Follow the on-screen instructions to complete the cancellation. 
                  <br />
                  2. Cancellation Fees: If applicable, cancellation fees may be charged based on the following
                  conditions: 
                  <br />
                   Cancellations made 7 days or more before the scheduled date: The fee amount would be 80% of the
                  Booking amount.
                  <br />
                   Cancellations made within 3 days of the scheduled date: The fee amount would be 50% of the Booking
                  amount.
                  <br />
                  Cancellations made within 1 day before the scheduled date: The fee amount would be 100% of the Booking
                  amount. Emergency or exceptional circumstances may be considered for fee waivers; contact our support
                  team for assistance. 
                  <br />
                   ---
                  <br />
                  Cookie Policy
                  <br />
                   <br />
                  1. Use of Cookies: We use cookies on our website and app to enhance your experience and provide
                  personalized services. 
                  <br />
                  2. Opt-Out Options: You can opt-out of cookie tracking. For more details, refer to our privacy
                  settings or cookie preferences.
                  <br />
                   ---
                  <br />
                  Terms of Service Updates:
                  <br />
                   <br />
                   1. Notification of Changes: We will notify you of any changes to our terms and conditions via email
                  or through in-app notifications. 
                  <br />2 User Acknowledgment: By continuing to use our services, you acknowledge and accept any updates
                  to the terms. 
                  <br />
                  --- 
                  <br />
                  User Reviews and Rating Policy: 
                  <br />
                   <br />
                  1. Moderation: User-generated reviews and ratings are moderated to ensure content adheres to our
                  guidelines. 
                  <br />
                  2. Dispute Resolution: If you have concerns about a review or rating, there is a dispute resolution
                  process in place. Contact us for assistance.
                  <br />
                   --- 
                  <br />
                  Compliance with App Store Policies:
                  <br />
                   <br />
                   1. App Store Regulations: Our app complies with the policies of the app stores it is available on,
                  ensuring a secure and reliable experience. 
                  <br />
                  2. App Store Contact Information: For support related to app store transactions, please reach out to
                  us at hello.goessy@gmail.com
                  <br />
                   --- 
                  <br />
                  Legal Jurisdiction
                  <br />
                   <br />
                   1. Governing Law: All legal matters are subject to the laws of Ahmedabad, Gujrat. 
                  <br />
                  2. Dispute Resolution: In case of disputes, we follow a process of arbitration or mediation for fair
                  and efficient resolution.
                  <br />
                   ---
                </p>
              </div>
            </div>
            <div className="ellipse-5" />
          </>
        )}

        <div
          className="frame-5"
          style={{
            borderRadius: screenWidth < 1920 ? "20px" : screenWidth >= 1920 ? "40px" : undefined,
            boxShadow:
              screenWidth < 1920 ? "0px 0px 4px #00000040" : screenWidth >= 1920 ? "0px 0px 10px #00000040" : undefined,
            height: screenWidth < 1920 ? "2786px" : screenWidth >= 1920 ? "535px" : undefined,
            left: screenWidth < 1920 ? "22px" : screenWidth >= 1920 ? "99px" : undefined,
            position: screenWidth < 1920 ? "absolute" : screenWidth >= 1920 ? "fixed" : undefined,
            top: screenWidth < 1920 ? "204px" : screenWidth >= 1920 ? "177px" : undefined,
            width: screenWidth < 1920 ? "330px" : screenWidth >= 1920 ? "528px" : undefined,
          }}
        >
          {screenWidth < 1920 && (
            <p className="p">
              Privacy Policies
              <br />
              <br />
              1. Data Collection: We collect information, including but not limited to names, emails, and locations, to
              enhance user experiences and provide personalized services.
              <br />
               2. Data Usage: Collected data may be used for internal purposes, such as personalization and
              communication with users.
              <br />
               3. Data Security: We employ industry-standard security measures to protect user information from
              unauthorized access. 
              <br />
               ---
              <br />
              Shipping Policy 
              <br />
               <br />
              1. Delivery Time: Estimated delivery times vary based on user locations and travel plans. 
              <br />
              2. Shipping Fees: All applicable shipping fees are communicated clearly before purchase. 
              <br />
              3. Returns: Refer to our Refund Policy for information on returns.
              <br />
               ---
              <br />
               Data Security Policy:
              <br />
               1. Data Protection: We use industry-standard security measures to safeguard user information. 
              <br />
              2. Data Sharing: User information is not sold to third parties. 
              <br />
              --- 
              <br />
              Refund Policy
              <br />
               1. Eligibility: Refunds may be available for specific products or services, subject to conditions. 
              <br />
              2. Refund Process: Refund requests must adhere to specific guidelines and timeframes outlined in our
              Refund Policy.
              <br />
               ---
              <br />
               Wallet Policy:
              <br />
               1. Wallet Use: Users can utilize wallet balances for service payments, including bookings and purchases. 
              <br />
              2. No Deposits: Direct deposits into the wallet are not allowed. Wallet balances are generated through
              refunds after bank transfers.
              <br />
               3. Refunds to Wallet: Refunded amounts for services or purchases will be credited to the user&#39;s
              wallet balance. 
              <br />
              4. Transferring to Bank: Users can transfer funds from their wallets to their bank account at any time. 
              <br />
              --- 
              <br />
              Cancellation Policy
              <br />
               <br />
               1. Cancellation Process: To cancel bookings or services, follow these steps: 
              <br />
              Log in to your account.
              <br />
               Navigate to the &#34;Bookings&#34; or &#34;Services&#34; section. - Select the booking or service you
              wish to cancel.
              <br />
               Follow the on-screen instructions to complete the cancellation. 
              <br />
              2. Cancellation Fees: If applicable, cancellation fees may be charged based on the following conditions: 
              <br />
               Cancellations made 7 days or more before the scheduled date: The fee amount would be 80% of the Booking
              amount.
              <br />
               Cancellations made within 3 days of the scheduled date: The fee amount would be 50% of the Booking
              amount.
              <br />
              Cancellations made within 1 day before the scheduled date: The fee amount would be 100% of the Booking
              amount. Emergency or exceptional circumstances may be considered for fee waivers; contact our support team
              for assistance. 
              <br />
               ---
              <br />
              Cookie Policy
              <br />
               <br />
              1. Use of Cookies: We use cookies on our website and app to enhance your experience and provide
              personalized services. 
              <br />
              2. Opt-Out Options: You can opt-out of cookie tracking. For more details, refer to our privacy settings or
              cookie preferences.
              <br />
               ---
              <br />
              Terms of Service Updates:
              <br />
               <br />
               1. Notification of Changes: We will notify you of any changes to our terms and conditions via email or
              through in-app notifications. 
              <br />2 User Acknowledgment: By continuing to use our services, you acknowledge and accept any updates to
              the terms. 
              <br />
              --- 
              <br />
              User Reviews and Rating Policy: 
              <br />
               <br />
              1. Moderation: User-generated reviews and ratings are moderated to ensure content adheres to our
              guidelines. 
              <br />
              2. Dispute Resolution: If you have concerns about a review or rating, there is a dispute resolution
              process in place. Contact us for assistance.
              <br />
               --- 
              <br />
              Compliance with App Store Policies:
              <br />
               <br />
               1. App Store Regulations: Our app complies with the policies of the app stores it is available on,
              ensuring a secure and reliable experience. 
              <br />
              2. App Store Contact Information: For support related to app store transactions, please reach out to us
              at hello.goessy@gmail.com
              <br />
               --- 
              <br />
              Legal Jurisdiction
              <br />
               <br />
               1. Governing Law: All legal matters are subject to the laws of Ahmedabad, Gujrat. 
              <br />
              2. Dispute Resolution: In case of disputes, we follow a process of arbitration or mediation for fair and
              efficient resolution.
              <br />
               ---
            </p>
          )}

          {screenWidth >= 1920 && (
            <>
              <div className="frame-6">
                <div className="text-wrapper-8">Terms and Conditions</div>
              </div>
              <div className="frame-7">
                <div className="text-wrapper-8">Privacy Policies</div>
              </div>
              <div className="frame-8">
                <div className="text-wrapper-8">Contact Us</div>
              </div>
            </>
          )}
        </div>
        {screenWidth < 1920 && (
          <>
            <div className="navbar">
              <a
                className="link-home"
                href="https://www.figma.com/design/TwClzvPbm6dQLNGBhf7ycp?node-id=2-2"
                rel="noopener noreferrer"
                target="_blank"
              >
                Home
              </a>
              <div className="link-our">Our Destinations</div>
              <a
                className="link-lokally-AI"
                href="https://www.figma.com/design/TwClzvPbm6dQLNGBhf7ycp?node-id=122-321"
                rel="noopener noreferrer"
                target="_blank"
              >
                Lokally AI
              </a>
              <div className="link-business-with">Business with us</div>
              <a
                className="link-about-us"
                href="https://www.figma.com/design/TwClzvPbm6dQLNGBhf7ycp?node-id=122-1271"
                rel="noopener noreferrer"
                target="_blank"
              >
                About Us
              </a>
              <div className="overlap-group-wrapper">
                <div className="overlap-group-4">
                  <div className="rectangle" />
                  <div className="text-wrapper-9">Menu</div>
                  <img className="arrow" alt="Arrow" src="/img/arrow-1.svg" />
                </div>
              </div>
              <div className="rectangle-2" />
              <img className="goessy-logo-pdf-2" alt="Goessy logo pdf" src="/img/goessy-logo-pdf.png" />
            </div>
            <div className="frame-wrapper">
              <div className="frame-9">
                <div className="frame-10">
                  <div className="text-wrapper-10">Terms and Conditions</div>
                </div>
                <div className="frame-11">
                  <div className="text-wrapper-11">Privacy Policies</div>
                </div>
                <div className="frame-12">
                  <div className="text-wrapper-12">Contact Us</div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
