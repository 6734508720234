import React from "react";
import { Link } from "react-router-dom";
import { useWindowWidth } from "../../breakpoints";
import "./style.css";

export const AboutUsPage = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="about-US-page">
      <div
        className="about-US-page-mob"
        style={{
          height: screenWidth < 1920 ? "2987px" : screenWidth >= 1920 ? "4141px" : undefined,
          width: screenWidth < 1920 ? "374px" : screenWidth >= 1920 ? "1920px" : undefined,
        }}
      >
        <div
          className="overlap-51"
          style={{
            height: screenWidth < 1920 ? "2955px" : screenWidth >= 1920 ? "2897px" : undefined,
            left: screenWidth < 1920 ? "-128px" : screenWidth >= 1920 ? "0" : undefined,
            top: screenWidth < 1920 ? "134px" : screenWidth >= 1920 ? "-402px" : undefined,
            width: screenWidth < 1920 ? "591px" : screenWidth >= 1920 ? "1920px" : undefined,
          }}
        >
          {screenWidth < 1920 && (
            <>
              <div className="ellipse-35" />
              <div className="ellipse-36" />
              <img className="decor-35" alt="Decor" src="/img/decor-37.svg" />
            </>
          )}

          <div
            className="overlap-52"
            style={{
              height: screenWidth >= 1920 ? "2897px" : screenWidth < 1920 ? "2955px" : undefined,
              width: screenWidth >= 1920 ? "1920px" : screenWidth < 1920 ? "591px" : undefined,
            }}
          >
            {screenWidth >= 1920 && (
              <>
                <div className="group-31">
                  <div className="ellipse-37" />
                  <div className="ellipse-38" />
                  <div className="ellipse-39" />
                </div>
                <div className="group-32" />
                <div className="text-wrapper-102">Home</div>
                <div className="text-wrapper-103">Boutique Holidays</div>
                <div className="text-wrapper-104">Lokally AI</div>
                <div className="text-wrapper-105">About Us</div>
              </>
            )}

            <div
              className="overlap-53"
              style={{
                height: screenWidth < 1920 ? "895px" : screenWidth >= 1920 ? "310px" : undefined,
                left: screenWidth < 1920 ? "292px" : screenWidth >= 1920 ? "270px" : undefined,
                top: screenWidth < 1920 ? "356px" : screenWidth >= 1920 ? "765px" : undefined,
                width: screenWidth < 1920 ? "210px" : screenWidth >= 1920 ? "1384px" : undefined,
              }}
            >
              {screenWidth < 1920 && (
                <>
                  <img className="decor-36" alt="Decor" src="/img/decor-39.svg" />
                  <img className="decor-37" alt="Decor" src="/img/decor-42.svg" />
                </>
              )}

              {screenWidth >= 1920 && (
                <>
                  <div className="text-wrapper-106">What We Do.</div>
                  <p className="we-re-not-just-a">
                    We&#39;re not just a travel company;
                    <br /> We&#39;re your companions on the road to discovery.
                  </p>
                </>
              )}
            </div>
            {screenWidth >= 1920 && (
              <div className="group-wrapper">
                <div className="group-33">
                  <img className="may-post" alt="May post" src="/img/may-post-19-1.png" />
                  <div className="group-34">
                    <div className="frame-84">
                      <div className="frame-85">
                        <div className="group-35">
                          <p className="text-wrapper-107">
                            At goEssy, your trips are like exciting tales. We mix smart travel with a touch of Ultra
                            luxury to make your journeys super enjoyable.
                          </p>
                        </div>
                      </div>
                      <div className="text-wrapper-108">Our Vision</div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {screenWidth < 1920 && (
              <>
                <img className="decor-38" alt="Decor" src="/img/decor-40.svg" />
                <div className="ellipse-40" />
                <img className="decor-39" alt="Decor" src="/img/decor-41.svg" />
              </>
            )}

            <div
              className="frame-86"
              style={{
                height: screenWidth >= 1920 ? "614px" : screenWidth < 1920 ? "687px" : undefined,
                left: screenWidth >= 1920 ? "300px" : screenWidth < 1920 ? "0" : undefined,
                top: screenWidth >= 1920 ? "1524px" : screenWidth < 1920 ? "894px" : undefined,
                width: screenWidth >= 1920 ? "1320px" : screenWidth < 1920 ? "266px" : undefined,
              }}
            >
              <div
                className="frame-87"
                style={{
                  backgroundColor: screenWidth < 1920 ? "#f0da9080" : undefined,
                  borderRadius: screenWidth < 1920 ? "69.13px/90.39px" : undefined,
                  filter: screenWidth < 1920 ? "blur(156.51px);" : undefined,
                  height: screenWidth >= 1920 ? "614px" : screenWidth < 1920 ? "181px" : undefined,
                  left: screenWidth >= 1920 ? "694px" : screenWidth < 1920 ? "128px" : undefined,
                  opacity: screenWidth < 1920 ? "0.3" : undefined,
                  top: screenWidth >= 1920 ? "0" : screenWidth < 1920 ? "506px" : undefined,
                  width: screenWidth >= 1920 ? "626px" : screenWidth < 1920 ? "138px" : undefined,
                }}
              >
                {screenWidth >= 1920 && (
                  <>
                    <img className="item-14" alt="Item" src="/img/item-14.png" />
                    <img className="item-15" alt="Item" src="/img/item-15.png" />
                    <img className="item-16" alt="Item" src="/img/item-16.png" />
                    <img className="item-17" alt="Item" src="/img/image.png" />
                  </>
                )}
              </div>
              <div
                className="frame-88"
                style={{
                  backgroundImage: screenWidth >= 1920 ? "url(/img/item-13.png)" : undefined,
                  backgroundSize: screenWidth >= 1920 ? "100% 100%" : undefined,
                  height: screenWidth >= 1920 ? "614px" : screenWidth < 1920 ? "312px" : undefined,
                  width: screenWidth >= 1920 ? "624px" : screenWidth < 1920 ? "258px" : undefined,
                }}
              >
                {screenWidth >= 1920 && (
                  <div className="group-36">
                    <div className="frame-89">
                      <p className="we-mix-smart-travel">
                        We mix smart travel with a touch of Ultra luxury Whether you&#39;re exploring or treating
                        yourself, we&#39;re here for you. Come along
                      </p>
                      <div className="text-wrapper-109">Our Mission</div>
                    </div>
                  </div>
                )}

                {screenWidth < 1920 && (
                  <>
                    <div className="ellipse-41" />
                    <div className="ellipse-42" />
                  </>
                )}
              </div>
            </div>
            {screenWidth >= 1920 && (
              <>
                <img className="decor-40" alt="Decor" src="/img/decor-20.svg" />
                <img className="decor-41" alt="Decor" src="/img/decor-21.svg" />
                <img className="goessy-logo-pdf-8" alt="Goessy logo pdf" src="/img/goessy-logo-pdf.png" />
              </>
            )}
          </div>
          {screenWidth < 1920 && (
            <>
              <div className="frame-90">
                <div className="overlap-54">
                  <div className="item-18">
                    <img
                      className="goessy-black-logo-7"
                      alt="Goessy black logo"
                      src="/img/goessy-black-logo-final-1-2-3-2.svg"
                    />
                    <div className="text-wrapper-110">All Rights Reserverd@goEssy</div>
                    <div className="list-7">
                      <div className="link-item-17">
                        <div className="symbol-19"></div>
                      </div>
                      <div className="link-item-18">
                        <div className="symbol-20"></div>
                      </div>
                      <div className="link-item-19">
                        <div className="symbol-21"></div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-91">
                    <div className="frame-92">
                      <div className="frame-93">
                        <div className="overlap-group-24">
                          <div className="text-wrapper-111">Home</div>
                          <img className="line-17" alt="Line" src="/img/line-218-3-2.svg" />
                        </div>
                      </div>
                      <div className="frame-94">
                        <div className="overlap-55">
                          <div className="text-wrapper-111">Boutique Holidays</div>
                          <img className="line-17" alt="Line" src="/img/line-219-3.svg" />
                        </div>
                      </div>
                      <div className="frame-95">
                        <div className="overlap-56">
                          <div className="text-wrapper-111">Lokally AI</div>
                          <img className="line-17" alt="Line" src="/img/line-220-3.svg" />
                        </div>
                      </div>
                    </div>
                    <div className="frame-96">
                      <div className="frame-97">
                        <div className="overlap-group-25">
                          <div className="text-wrapper-111">About Us</div>
                          <img className="line-18" alt="Line" src="/img/line-221-3.svg" />
                        </div>
                      </div>
                      <div className="frame-98">
                        <div className="overlap-57">
                          <div className="text-wrapper-111">Blog</div>
                          <img className="line-17" alt="Line" src="/img/line-222-3.svg" />
                        </div>
                      </div>
                      <div className="frame-99">
                        <div className="overlap-58">
                          <div className="text-wrapper-111">Terms and Policies</div>
                          <img className="line-18" alt="Line" src="/img/line-223-3.svg" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-37">
                <div className="text-wrapper-112">What We Do.</div>
                <p className="we-re-not-just-a-2">
                  We&#39;re not just a travel company;
                  <br /> We&#39;re your companions.
                </p>
              </div>
              <div className="section">
                <div className="overlap-59">
                  <div className="frame-100">
                    <label className="text-wrapper-113" htmlFor="input-1">
                      First Name
                    </label>
                    <div className="input">
                      <input className="container-2" id="input-1" placeholder="Enter Your First Name" type="text" />
                    </div>
                  </div>
                  <div className="frame-101">
                    <div className="overlap-group-26">
                      <div className="text-wrapper-114">Get in touch.</div>
                      <div className="text-wrapper-115">info@goessy.com</div>
                    </div>
                  </div>
                  <img className="decor-42" alt="Decor" src="/img/decor-43.svg" />
                </div>
                <div className="frame-102">
                  <label className="text-wrapper-113" htmlFor="input-2">
                    Last Name
                  </label>
                  <div className="input">
                    <input className="container-2" id="input-2" placeholder="Enter Your Last Name" type="text" />
                  </div>
                </div>
                <div className="frame-103">
                  <label className="text-wrapper-113" htmlFor="input-3">
                    City
                  </label>
                  <div className="input">
                    <input className="container-2" id="input-3" placeholder="Enter Your City Name" type="text" />
                  </div>
                </div>
                <div className="frame-104">
                  <label className="text-wrapper-113" htmlFor="input-4">
                    State
                  </label>
                  <div className="input">
                    <input className="container-3" id="input-4" placeholder="Enter Your State Name" type="text" />
                  </div>
                </div>
                <div className="frame-105">
                  <label className="text-wrapper-113" htmlFor="input-5">
                    Email
                  </label>
                  <div className="input">
                    <input className="container-3" id="input-5" placeholder="Enter Your Email" type="email" />
                  </div>
                </div>
                <button className="button">
                  <div className="text-wrapper-116">Submit</div>
                </button>
                <div className="frame-106">
                  <div className="container-wrapper">
                    <div className="container-4">
                      <div className="div-4">Enter Your query</div>
                    </div>
                  </div>
                  <div className="text-wrapper-113">Description</div>
                </div>
                <div className="frame-107">
                  <div className="input-2">
                    <div className="enter-your-contact-wrapper">
                      <p className="div-4">
                        <span className="text-wrapper-117">Enter Your Contact Number</span>
                      </p>
                    </div>
                  </div>
                  <div className="text-wrapper-113">Contact Number</div>
                </div>
              </div>
              <img className="decor-43" alt="Decor" src="/img/decor-38.svg" />
              <div className="frame-108">
                <div className="group-38">
                  <div className="frame-109">
                    <p className="we-mix-smart-travel-2">
                      We mix smart travel with a touch of Ultra luxury Whether you&#39;re exploring or treating
                      yourself, we&#39;re here for you. Come along
                    </p>
                    <div className="text-wrapper-118">Our Mission</div>
                  </div>
                </div>
              </div>
              <div className="frame-110">
                <div className="frame-111">
                  <div className="group-38">
                    <div className="frame-109">
                      <p className="we-mix-smart-travel-2">
                        We mix smart travel with a touch of Ultra luxury Whether you&#39;re exploring or treating
                        yourself, we&#39;re here for you. Come along
                      </p>
                      <div className="text-wrapper-118">Our Vision</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-39">
                <img className="item-19" alt="Item" src="/img/item-14-2.png" />
                <img className="item-20" alt="Item" src="/img/item-15-2.png" />
                <img className="item-21" alt="Item" src="/img/item-16-2.png" />
                <img className="item-22" alt="Item" src="/img/item-17.png" />
              </div>
            </>
          )}

          {screenWidth >= 1920 && (
            <Link className="frame-112" to="/blog-page">
              <Link className="text-wrapper-119" to="/blog-page">
                Blogs
              </Link>
            </Link>
          )}
        </div>
        <div
          className="group-40"
          style={{
            height: screenWidth < 1920 ? "946px" : screenWidth >= 1920 ? "609px" : undefined,
            left: screenWidth < 1920 ? "832px" : screenWidth >= 1920 ? "73px" : undefined,
            top: screenWidth < 1920 ? "869px" : screenWidth >= 1920 ? "3627px" : undefined,
            width: screenWidth < 1920 ? "624px" : screenWidth >= 1920 ? "1972px" : undefined,
          }}
        >
          {screenWidth >= 1920 && (
            <div className="row-7">
              <div className="overlap-60">
                <div className="frame-113">
                  <div className="text-wrapper-120">All Rights Reserverd@goEssy</div>
                  <div className="list-8">
                    <div className="link-item-20">
                      <div className="symbol-22"></div>
                    </div>
                    <div className="link-item-21">
                      <div className="symbol-23"></div>
                    </div>
                    <div className="link-item-22">
                      <div className="symbol-24"></div>
                    </div>
                  </div>
                  <img
                    className="goessy-black-logo-8"
                    alt="Goessy black logo"
                    src="/img/goessy-black-logo-final-1-2-4.svg"
                  />
                </div>
                <div className="frame-114">
                  <div className="overlap-group-27">
                    <Link className="text-wrapper-121" to="/home-page">
                      Home
                    </Link>
                    <img className="line-19" alt="Line" src="/img/line-218-4.svg" />
                  </div>
                  <div className="overlap-61">
                    <div className="text-wrapper-122">Boutique Holidays</div>
                    <img className="line-19" alt="Line" src="/img/line-218-4.svg" />
                  </div>
                  <div className="overlap-62">
                    <Link className="text-wrapper-121" to="/lokally-ai">
                      Lokally AI
                    </Link>
                    <img className="line-19" alt="Line" src="/img/line-218-4.svg" />
                  </div>
                  <div className="overlap-63">
                    <div className="text-wrapper-122">About Us</div>
                    <img className="line-20" alt="Line" src="/img/line-218-4.svg" />
                  </div>
                  <div className="overlap-64">
                    <Link className="text-wrapper-121" to="/blog-page">
                      Blog
                    </Link>
                    <img className="line-21" alt="Line" src="/img/line-218-4.svg" />
                  </div>
                  <div className="overlap-65">
                    <div className="text-wrapper-122">Terms and Policies</div>
                    <img className="line-20" alt="Line" src="/img/line-218-4.svg" />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className="ellipse-43"
            style={{
              backgroundColor: screenWidth >= 1920 ? "#f0da9080" : screenWidth < 1920 ? "#f0da90" : undefined,
              left: screenWidth >= 1920 ? "1578px" : screenWidth < 1920 ? "110px" : undefined,
              top: screenWidth >= 1920 ? "363px" : screenWidth < 1920 ? "700px" : undefined,
            }}
          />
          {screenWidth < 1920 && (
            <>
              <div className="ellipse-38" />
              <div className="ellipse-39" />
            </>
          )}
        </div>
        <div
          className="navbar-4"
          style={{
            backgroundColor: screenWidth < 1920 ? "#ffffff" : undefined,
            boxShadow: screenWidth < 1920 ? "0px 0px 3.76px #ffa500" : undefined,
            height: screenWidth < 1920 ? "76px" : screenWidth >= 1920 ? "732px" : undefined,
            left: screenWidth < 1920 ? "0" : screenWidth >= 1920 ? "299px" : undefined,
            top: screenWidth < 1920 ? "0" : screenWidth >= 1920 ? "2695px" : undefined,
            width: screenWidth < 1920 ? "375px" : screenWidth >= 1920 ? "1321px" : undefined,
          }}
        >
          {screenWidth < 1920 && (
            <>
              <a
                className="link-home-4"
                href="https://www.figma.com/design/TwClzvPbm6dQLNGBhf7ycp?node-id=2-2"
                rel="noopener noreferrer"
                target="_blank"
              >
                Home
              </a>
              <div className="link-our-4">Our Destinations</div>
              <a
                className="link-lokally-AI-4"
                href="https://www.figma.com/design/TwClzvPbm6dQLNGBhf7ycp?node-id=122-321"
                rel="noopener noreferrer"
                target="_blank"
              >
                Lokally AI
              </a>
              <div className="link-business-with-4">Business with us</div>
              <a
                className="link-about-us-4"
                href="https://www.figma.com/design/TwClzvPbm6dQLNGBhf7ycp?node-id=122-1271"
                rel="noopener noreferrer"
                target="_blank"
              >
                About Us
              </a>
            </>
          )}

          {screenWidth >= 1920 && (
            <>
              <div className="label-first-name">First Name</div>
              <div className="input-3">
                <div className="container-5">
                  <div className="text-wrapper-123">Enter Your First Name</div>
                </div>
              </div>
              <div className="label-last-name">Last Name</div>
              <div className="input-4">
                <div className="container-5">
                  <div className="text-wrapper-123">Enter Your Last Name</div>
                </div>
              </div>
              <div className="label-email">Email</div>
              <div className="input-5">
                <div className="container-5">
                  <div className="text-wrapper-124">Enter Your Email</div>
                </div>
              </div>
              <div className="label-contact-number">Contact Number</div>
              <div className="input-6">
                <div className="container-5">
                  <div className="text-wrapper-125">Contact Number</div>
                </div>
              </div>
              <div className="label-city">City</div>
              <div className="input-7">
                <div className="container-6">
                  <div className="text-wrapper-126">Enter Your City Name</div>
                </div>
              </div>
              <div className="input-8">
                <div className="container-6">
                  <div className="text-wrapper-126">Enter Your query</div>
                </div>
              </div>
              <div className="label-state">State</div>
              <div className="input-9">
                <div className="container-5">
                  <div className="text-wrapper-127">Enter Your State Name</div>
                </div>
              </div>
              <div className="label-description">Description</div>
              <button className="button-2">
                <div className="text-wrapper-128">Submit</div>
              </button>
            </>
          )}

          <div
            className="frame-115"
            style={{
              height: screenWidth >= 1920 ? "142px" : screenWidth < 1920 ? "34px" : undefined,
              left: screenWidth >= 1920 ? "445px" : screenWidth < 1920 ? "264px" : undefined,
              top: screenWidth >= 1920 ? "0" : screenWidth < 1920 ? "23px" : undefined,
              width: screenWidth >= 1920 ? "430px" : screenWidth < 1920 ? "92px" : undefined,
            }}
          >
            {screenWidth >= 1920 && (
              <>
                <div className="text-wrapper-129">Get in touch</div>
                <div className="text-wrapper-130">info@goessy.com</div>
              </>
            )}

            {screenWidth < 1920 && (
              <div className="overlap-group-28">
                <div className="rectangle-9" />
                <div className="text-wrapper-131">Menu</div>
                <img className="arrow-4" alt="Arrow" src="/img/arrow-1.svg" />
              </div>
            )}
          </div>
          {screenWidth < 1920 && (
            <img className="goessy-logo-pdf-9" alt="Goessy logo pdf" src="/img/goessy-logo-pdf.png" />
          )}
        </div>
      </div>
    </div>
  );
};
