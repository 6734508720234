import React from "react";
import { Link } from "react-router-dom";
import { useWindowWidth } from "../../breakpoints";
import "./style.css";

export const HomePage = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="home-page">
      <div
        className="homepage-mobile"
        style={{
          height: screenWidth < 1920 ? "2137px" : screenWidth >= 1920 ? "3173px" : undefined,
          width: screenWidth < 1920 ? "374px" : screenWidth >= 1920 ? "1920px" : undefined,
        }}
      >
        <div
          className="overlap-8"
          style={{
            height: screenWidth >= 1920 ? "801px" : screenWidth < 1920 ? "2050px" : undefined,
            left: screenWidth >= 1920 ? "0" : screenWidth < 1920 ? "-64px" : undefined,
            top: screenWidth >= 1920 ? "2554px" : screenWidth < 1920 ? "76px" : undefined,
            width: screenWidth >= 1920 ? "1848px" : screenWidth < 1920 ? "502px" : undefined,
          }}
        >
          {screenWidth >= 1920 && (
            <div className="overlap-wrapper">
              <div className="overlap-9">
                <div className="frame-13">
                  <div className="text-wrapper-13">All Rights Reserverd@goEssy</div>
                  <div className="list-2">
                    <div className="link-item-2">
                      <div className="symbol-4"></div>
                    </div>
                    <div className="link-item-3">
                      <div className="symbol-5"></div>
                    </div>
                    <div className="link-item-4">
                      <div className="symbol-6"></div>
                    </div>
                  </div>
                  <img
                    className="goessy-black-logo-2"
                    alt="Goessy black logo"
                    src="/img/goessy-black-logo-final-1-2-1-2.svg"
                  />
                </div>
                <div className="frame-14">
                  <div className="overlap-10">
                    <div className="text-wrapper-14">Home</div>
                    <img className="line-4" alt="Line" src="/img/line-218-1.svg" />
                  </div>
                  <div className="overlap-11">
                    <div className="text-wrapper-14">Boutique Holidays</div>
                    <img className="line-4" alt="Line" src="/img/line-218-1.svg" />
                  </div>
                  <div className="overlap-12">
                    <Link className="text-wrapper-15" to="/lokally-ai">
                      Lokally AI
                    </Link>
                    <img className="line-4" alt="Line" src="/img/line-218-1.svg" />
                  </div>
                  <div className="overlap-group-5">
                    <Link className="text-wrapper-15" to="/about-us-page">
                      About Us
                    </Link>
                    <img className="line-5" alt="Line" src="/img/line-218-1.svg" />
                  </div>
                  <div className="overlap-13">
                    <Link className="text-wrapper-15" to="/blog-page">
                      Blog
                    </Link>
                    <img className="line-6" alt="Line" src="/img/line-218-1.svg" />
                  </div>
                  <div className="overlap-14">
                    <div className="text-wrapper-14">Terms and Policies</div>
                    <img className="line-5" alt="Line" src="/img/line-218-1.svg" />
                  </div>
                </div>
              </div>
            </div>
          )}

          <img
            className="decor-5"
            style={{
              height: screenWidth >= 1920 ? "619px" : screenWidth < 1920 ? "218px" : undefined,
              left: screenWidth >= 1920 ? "0" : screenWidth < 1920 ? "64px" : undefined,
              top: screenWidth >= 1920 ? "0" : screenWidth < 1920 ? "9px" : undefined,
              width: screenWidth >= 1920 ? "744px" : screenWidth < 1920 ? "143px" : undefined,
            }}
            alt="Decor"
            src={screenWidth >= 1920 ? "/img/decor-5.svg" : screenWidth < 1920 ? "/img/decor-5-2.svg" : undefined}
          />
          {screenWidth < 1920 && (
            <>
              <div className="group-4">
                <div className="ellipse-6" />
                <div className="overlap-group-6">
                  <img className="decor-6" alt="Decor" src="/img/decor-9-2.svg" />
                  <img className="decor-7" alt="Decor" src="/img/decor-11-2.svg" />
                  <img className="decor-8" alt="Decor" src="/img/decor-12-2.svg" />
                  <div className="group-5">
                    <div className="ellipse-7" />
                    <div className="ellipse-8" />
                    <div className="ellipse-9" />
                  </div>
                </div>
                <img className="decor-9" alt="Decor" src="/img/decor-10-2.svg" />
              </div>
              <div className="group-6">
                <div className="overlap-15">
                  <img className="decor-10" alt="Decor" src="/img/decor-13.svg" />
                  <img className="decor-11" alt="Decor" src="/img/decor-15-2.svg" />
                  <img className="decor-12" alt="Decor" src="/img/decor-16-2.svg" />
                  <div className="group-7">
                    <div className="ellipse-10" />
                    <div className="ellipse-11" />
                  </div>
                </div>
                <img className="decor-13" alt="Decor" src="/img/decor-14.svg" />
                <div className="ellipse-12" />
              </div>
            </>
          )}

          <div
            className="ellipse-13"
            style={{
              backgroundColor: screenWidth >= 1920 ? "#c090f080" : screenWidth < 1920 ? "#f0da90" : undefined,
              borderRadius:
                screenWidth >= 1920 ? "178.5px/175.93px" : screenWidth < 1920 ? "120.5px/75.24px" : undefined,
              filter: screenWidth >= 1920 ? "blur(128.42px);" : screenWidth < 1920 ? "blur(122.34px);" : undefined,
              height: screenWidth >= 1920 ? "352px" : screenWidth < 1920 ? "150px" : undefined,
              left: screenWidth >= 1920 ? "732px" : screenWidth < 1920 ? "134px" : undefined,
              top: screenWidth >= 1920 ? "449px" : screenWidth < 1920 ? "548px" : undefined,
              width: screenWidth >= 1920 ? "357px" : screenWidth < 1920 ? "241px" : undefined,
            }}
          />
          {screenWidth < 1920 && (
            <>
              <img className="decor-14" alt="Decor" src="/img/decor-6.svg" />
              <img className="decor-15" alt="Decor" src="/img/decor-7.svg" />
              <div className="content">
                <div className="item">
                  <p className="revaluing-now-travel">
                    Revaluing Now Travel <br />
                    in Style
                  </p>
                </div>
              </div>
              <div className="item-2">
                <div className="overlap-16">
                  <img className="image" alt="Image" src="/img/image-1.png" />
                  <img className="vector" alt="Vector" src="/img/vector-5.svg" />
                  <div className="new-style-new">
                    New Style.
                    <br />
                    New Comfort.
                    <br />
                    New Fashion.
                  </div>
                </div>
              </div>
              <div className="row-2">
                <div className="item-3">
                  <img className="vector-2" alt="Vector" src="/img/vector-1.png" />
                  <div className="overlap-group-7">
                    <div className="text-wrapper-16">Like Never Before.</div>
                    <p className="experince">
                      <span className="span">Ex</span>
                      <span className="text-wrapper-17">perince</span>
                    </p>
                  </div>
                  <img className="content-2" alt="Content" src="/img/content-2.svg" />
                </div>
                <div className="item-4">
                  <div className="overlap-17">
                    <img className="vector-3" alt="Vector" src="/img/vector-3.svg" />
                    <div className="text-wrapper-18">Like Never Before.</div>
                    <p className="explore">
                      <span className="text-wrapper-19">Ex</span>
                      <span className="text-wrapper-20">plore</span>
                    </p>
                  </div>
                  <img className="content-2" alt="Content" src="/img/content-1-2.svg" />
                </div>
              </div>
              <div className="frame-15">
                <div className="item-5">
                  <div className="overlap-18">
                    <img className="vector-4" alt="Vector" src="/img/vector-4.svg" />
                    <div className="text-wrapper-21">Lokally AI.</div>
                  </div>
                  <div className="overlap-group-8">
                    <div className="content-3">
                      <p className="text-wrapper-22">Let us create amazing inspirational spaces!</p>
                    </div>
                    <div className="frame-16">
                      <div className="text-wrapper-23">Explore Now</div>
                      <img className="group-8" alt="Group" src="/img/group.png" />
                    </div>
                  </div>
                </div>
                <div className="item-wrapper">
                  <div className="item-6">
                    <img className="vector-5" alt="Vector" src="/img/vector-1.png" />
                    <div className="overlap-19">
                      <img className="vector-6" alt="Vector" src="/img/vector-6.svg" />
                      <div className="text-wrapper-24">Boutique Holidays.</div>
                    </div>
                    <div className="overlap-20">
                      <div className="content-4">
                        <p className="text-wrapper-25">Let us create amazing inspirational spaces!</p>
                      </div>
                      <div className="group-9">
                        <div className="overlap-group-9">
                          <img className="vector-7" alt="Vector" src="/img/vector-7-2.svg" />
                          <div className="text-wrapper-26">Explore Now</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-10">
                <p className="text-wrapper-27">
                  See the impact of good, conversion-oriented design on your business. See the impact of good, See the
                  impact of good, See the impact of good,
                </p>
                <div className="cta-link-wrapper">
                  <div className="cta-link">
                    <div className="cta-link-wrapper-2">
                      <div className="text-wrapper-28">Create Your Dream Trip</div>
                      <img className="vector-8" alt="Vector" src="/img/vector-8.svg" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-11">
                <div className="text-wrapper-29">OUR PRODUCTS.</div>
                <p className="text-wrapper-30">
                  We are in developement We are in developement We are in developement in developement We are in
                  developement
                </p>
              </div>
              <div className="frame-17">
                <div className="overlap-21">
                  <div className="item-7">
                    <img
                      className="goessy-black-logo-3"
                      alt="Goessy black logo"
                      src="/img/goessy-black-logo-final-1-2-1.svg"
                    />
                    <div className="text-wrapper-31">All Rights Reserverd@goEssy</div>
                    <div className="list-3">
                      <div className="link-item-5">
                        <div className="symbol-7"></div>
                      </div>
                      <div className="link-item-6">
                        <div className="symbol-8"></div>
                      </div>
                      <div className="link-item-7">
                        <div className="symbol-9"></div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-18">
                    <div className="frame-19">
                      <div className="frame-20">
                        <div className="overlap-group-10">
                          <div className="text-wrapper-32">Home</div>
                          <img className="line-7" alt="Line" src="/img/line-218-1-2.svg" />
                        </div>
                      </div>
                      <div className="frame-21">
                        <div className="overlap-22">
                          <div className="text-wrapper-32">Boutique Holidays</div>
                          <img className="line-7" alt="Line" src="/img/line-219-1.svg" />
                        </div>
                      </div>
                      <div className="frame-22">
                        <div className="overlap-23">
                          <div className="text-wrapper-32">Lokally AI</div>
                          <img className="line-7" alt="Line" src="/img/line-220-1.svg" />
                        </div>
                      </div>
                    </div>
                    <div className="frame-23">
                      <div className="frame-24">
                        <div className="overlap-group-11">
                          <div className="text-wrapper-32">About Us</div>
                          <img className="line-8" alt="Line" src="/img/line-221-1.svg" />
                        </div>
                      </div>
                      <div className="frame-25">
                        <div className="overlap-24">
                          <div className="text-wrapper-32">Blog</div>
                          <img className="line-7" alt="Line" src="/img/line-222-1.svg" />
                        </div>
                      </div>
                      <div className="frame-26">
                        <div className="overlap-25">
                          <div className="text-wrapper-32">Terms and Policies</div>
                          <img className="line-8" alt="Line" src="/img/line-223-1.svg" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img className="decor-16" alt="Decor" src="/img/decor-8.svg" />
            </>
          )}
        </div>
        <div
          className="group-12"
          style={{
            height: screenWidth < 1920 ? "946px" : screenWidth >= 1920 ? "2466px" : undefined,
            left: screenWidth < 1920 ? "832px" : screenWidth >= 1920 ? "-131px" : undefined,
            top: screenWidth < 1920 ? "869px" : screenWidth >= 1920 ? "34px" : undefined,
            width: screenWidth < 1920 ? "624px" : screenWidth >= 1920 ? "2051px" : undefined,
          }}
        >
          {screenWidth < 1920 && (
            <>
              <div className="ellipse-14" />
              <div className="ellipse-15" />
              <div className="ellipse-16" />
            </>
          )}

          {screenWidth >= 1920 && (
            <>
              <div className="overlap-26">
                <img className="decor-17" alt="Decor" src="/img/decor-6-2.svg" />
                <div className="group-13">
                  <p className="text-wrapper-33">
                    See the impact of good, conversion-oriented design on your business. See the impact of good, See the
                    impact of good, See the impact of good,
                  </p>
                  <div className="group-14">
                    <div className="cta-link-2">
                      <div className="cta-link-wrapper-3">
                        <div className="text-wrapper-34">Create Your Dream Trip</div>
                        <img className="vector-9" alt="Vector" src="/img/vector.svg" />
                      </div>
                    </div>
                  </div>
                </div>
                <img className="goessy-logo-pdf-3" alt="Goessy logo pdf" src="/img/goessy-logo-pdf.png" />
                <img className="decor-18" alt="Decor" src="/img/decor-8-2.svg" />
                <img className="decor-19" alt="Decor" src="/img/decor-9.svg" />
                <div className="content-5">
                  <div className="revaluing-now-travel-wrapper">
                    <p className="revaluing-now-travel-2">
                      Revaluing Now Travel <br />
                      in Style
                    </p>
                  </div>
                  <div className="left">
                    <div className="item-8">
                      <div className="overlap-group-12">
                        <img className="image-2" alt="Image" src="/img/image-1-2.png" />
                        <img className="vector-10" alt="Vector" src="/img/vector-1.svg" />
                        <div className="new-style-new-2">
                          New Style.
                          <br />
                          New Comfort.
                          <br />
                          New Fashion.
                        </div>
                      </div>
                    </div>
                    <div className="row-3">
                      <div className="item-9">
                        <img className="vector-11" alt="Vector" src="/img/vector-2.png" />
                        <div className="overlap-group-13">
                          <div className="text-wrapper-35">Like Never Before.</div>
                          <p className="experience">
                            <span className="text-wrapper-36">Ex</span>
                            <span className="text-wrapper-37">perience</span>
                          </p>
                        </div>
                        <img className="content-6" alt="Content" src="/img/content.svg" />
                      </div>
                      <div className="item-10">
                        <div className="overlap-27">
                          <img className="vector-12" alt="Vector" src="/img/image.svg" />
                          <div className="text-wrapper-38">Like Never Before.</div>
                          <p className="explore-2">
                            <span className="text-wrapper-39">Ex</span>
                            <span className="text-wrapper-40">plore</span>
                          </p>
                        </div>
                        <img className="content-7" alt="Content" src="/img/content-1.svg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="group-15">
                  <div className="ellipse-14" />
                  <div className="ellipse-15" />
                  <div className="ellipse-16" />
                </div>
                <div className="group-16">
                  <div className="ellipse-14" />
                  <div className="ellipse-15" />
                  <div className="ellipse-16" />
                </div>
                <div className="group-17">
                  <div className="frame-27">
                    <div className="row-wrapper">
                      <div className="row-4">
                        <div className="item-11">
                          <img className="vector-13" alt="Vector" src="/img/vector-2.png" />
                          <div className="overlap-group-14">
                            <img className="vector-14" alt="Vector" src="/img/vector-6.png" />
                            <div className="text-wrapper-41">Boutique Holidays.</div>
                          </div>
                          <div className="content-8">
                            <p className="text-wrapper-42">Let us create amazing inspirational spaces!</p>
                            <div className="frame-28">
                              <div className="text-wrapper-43">Explore Now</div>
                              <img className="frame-29" alt="Frame" src="/img/frame.svg" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item-12">
                      <div className="overlap-28">
                        <img className="vector-15" alt="Vector" src="/img/vector-7.svg" />
                        <div className="text-wrapper-44">Lokally AI.</div>
                      </div>
                      <div className="content-9">
                        <p className="text-wrapper-45">Let us create amazing inspirational spaces!</p>
                        <div className="frame-30">
                          <img className="direct-instagram" alt="Direct instagram" src="/img/direct-instagram-1.svg" />
                          <div className="text-wrapper-43">Explore Now</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-18">
                    <div className="text-wrapper-46">OUR SAYINGS.</div>
                    <p className="text-wrapper-47">
                      We are in developement We are in developementWe are in developement,We are in developement We are
                      in developementWe are in developement
                    </p>
                  </div>
                </div>
              </div>
              <div className="text-wrapper-48">Home</div>
              <div className="text-wrapper-49">Lokally AI</div>
              <Link className="text-wrapper-50" to="/about-us-page">
                About Us
              </Link>
              <Link className="frame-31" to="/blog-page">
                <Link className="text-wrapper-51" to="/blog-page">
                  Blogs
                </Link>
              </Link>
            </>
          )}
        </div>
        {screenWidth < 1920 && (
          <div className="overlap-29">
            <div className="navbar-2">
              <a
                className="link-home-2"
                href="https://www.figma.com/design/TwClzvPbm6dQLNGBhf7ycp?node-id=2-2"
                rel="noopener noreferrer"
                target="_blank"
              >
                Home
              </a>
              <div className="link-our-2">Our Destinations</div>
              <a
                className="link-lokally-AI-2"
                href="https://www.figma.com/design/TwClzvPbm6dQLNGBhf7ycp?node-id=122-321"
                rel="noopener noreferrer"
                target="_blank"
              >
                Lokally AI
              </a>
              <div className="link-business-with-2">Business with us</div>
              <a
                className="link-about-us-2"
                href="https://www.figma.com/design/TwClzvPbm6dQLNGBhf7ycp?node-id=122-1271"
                rel="noopener noreferrer"
                target="_blank"
              >
                About Us
              </a>
              <div className="frame-32">
                <div className="overlap-group-15">
                  <div className="rectangle-3" />
                  <div className="text-wrapper-52">Menu</div>
                  <img className="arrow-2" alt="Arrow" src="/img/arrow-1.svg" />
                </div>
              </div>
            </div>
            <img className="goessy-logo-pdf-4" alt="Goessy logo pdf" src="/img/goessy-logo-pdf.png" />
          </div>
        )}

        {screenWidth >= 1920 && (
          <>
            <img className="decor-20" alt="Decor" src="/img/decor-2.png" />
            <div className="ellipse-17" />
          </>
        )}
      </div>
    </div>
  );
};
