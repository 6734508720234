import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

export const BlogPageInside = () => {
  return (
    <div className="blog-page-inside">
      <div className="div-2">
        <div className="overlap-30">
          <div className="ellipse-18" />
          <img className="decor-21" alt="Decor" src="/img/decor-10.svg" />
          <img className="decor-22" alt="Decor" src="/img/decor-11.svg" />
          <div className="ellipse-19" />
          <img className="decor-23" alt="Decor" src="/img/decor-12.svg" />
          <div className="ellipse-20" />
          <div className="ellipse-21" />
          <img className="rectangle-4" alt="Rectangle" src="/img/rectangle-228.png" />
          <img className="rectangle-5" alt="Rectangle" src="/img/rectangle-246.png" />
          <p className="unlocking-innovation">
            Unlocking Innovation: Zomato’s journey to seamless iOS code sharing &amp; distribution with Swift Package
            Manager
          </p>
          <p className="community-june">
            <span className="text-wrapper-53">Community</span>
            <span className="text-wrapper-54"> | June 6, 2024 </span>
          </p>
          <p className="strong-history-of">History of goEssy iOS apps</p>
          <p className="strong-history-of-2">
            Udaipur, the &#34;City of Lakes,&#34; is a mesmerizing blend of cultural heritage, natural beauty, and
            architectural marvels. Nestled amidst the Aravalli Hills of Rajasthan, Udaipur offers a captivating
            experience for travelers seeking a taste of royal India.
          </p>
          <p className="most-of-you-are">
            Most of you are probably familiar with the Zomato consumer app – the one you use to order food, explore
            restaurants, dine out etc. But what you might not know is that we have a lot more apps which fall under
            Zomato’s universe of iOS apps such as Blinkit, HyperPure, Zomato Restaurant Partner, Zomato Dining Partner
            etc. Initially it was easy to replicate things, over time it became evident that creating every app from
            scratch was inefficient and hard to manage especially when fundamental layers of an app were already in
            place. This is why all these apps share some part of the code originally written for the Zomato consumer
            <br />
            app.
          </p>
          <p className="udaipur-the-city-of">
            <span className="text-wrapper-55">
              Udaipur, the &#34;City of Lakes,&#34; is a mesmerizing blend of cultural heritage, natural beauty, and
              architectural marvels. Nestled amidst the Aravalli Hills of Rajasthan, Udaipur offers a captivating
              experience for travelers seeking a taste of royal India.
              <br />
              <br />
              Must-Sees in Udaipur:
              <br />
              <br />
            </span>
            <span className="text-wrapper-55">
              City Palace: A majestic complex showcasing Rajput architecture, boasting courtyards, pavilions, and
              museums.
              <br />
              Lake Pichola: The heart of Udaipur, offering boat rides with breathtaking views of the city and
              surrounding palaces.
              <br />
              Jag Mandir: A beautiful island palace in the middle of Lake Pichola, known for its intricate marble work
              and gardens.
              <br />
              Lake Palace: An opulent white marble palace standing on Jag Niwas Island, offering a luxurious stay
              experience.
              <br />
              Monsoon Palace: A hilltop palace providing panoramic views of the city and surrounding lakes.
              <br />
              Jagdish Temple: A richly carved Hindu temple dedicated to Lord Vishnu, showcasing intricate sculptures and
              architecture.
              <br />
              Saheliyon Ki Bari:&nbsp;&nbsp;A &#34;Garden of Maids,&#34; featuring lush greenery, fountains, and
              courtyards, once a royal retreat.
              <br />
              Bagore Ki Haveli: A former royal residence showcasing Rajput and Mughal architectural influences, now a
              museum
              <br />
            </span>
          </p>
          <p className="beyond-the-sights">
            <span className="text-wrapper-55">
              Beyond the Sights:
              <br />
              <br />
            </span>
            <span className="text-wrapper-55">
              Enjoy a boat ride on Lake Pichola: Experience the beauty of the city and its palaces from a different
              perspective.
              <br />
              Indulge in a cultural performance: Witness traditional Rajasthani folk dances and music showcasing the
              region&#39;s rich heritage.
              <br />
              Explore the local markets:&nbsp;&nbsp;Browse through colorful handicrafts, textiles, and souvenirs at
              Hathi Pol Bazaar and Saheliyon Ki Bari Market.
              <br />
              Take a cooking class: Learn the secrets of Rajasthani cuisine and recreate delicious dishes at home.
              <br />
              Embark on a wildlife safari: Visit Sajjangarh Wildlife Sanctuary or Kumbhalgarh Wildlife Sanctuary to spot
              leopards, hyenas, and abundant birdlife.
              <br />
            </span>
            <span className="text-wrapper-55">
              <br />
            </span>
            <span className="text-wrapper-55">
              <br />
              Under the Radar:
              <br />
            </span>
            <span className="text-wrapper-55">
              <br />
            </span>
            <span className="text-wrapper-55">
              Vintage Car Museum: Housing a collection of classic cars once owned by the Mewar royal family.
              <br />
              Neemach Mata Temple: A serene cave temple dedicated to Goddess Durga, offering a spiritual atmosphere.
              <br />
              Shilpgram: A crafts village showcasing traditional Rajasthani handicrafts and demonstrations by artisans.
              <br />
              Doodh Talai: A scenic lake surrounded by hills, perfect for a peaceful picnic or nature walk.
              <br />
            </span>
            <span className="text-wrapper-55">
              <br />
              <br />
            </span>
            <span className="text-wrapper-55">
              Planning Your Trip:
              <br />
            </span>
            <span className="text-wrapper-55">
              <br />
            </span>
            <span className="text-wrapper-55">
              Udaipur is a year-round destination, but the best time to visit is during the pleasant weather of winter
              (October to March). Summers can be quite hot, while monsoons (July to September) bring lush greenery and
              occasional rain showers.
              <br />
              The city offers a variety of accommodation options, from luxurious palace hotels to budget guesthouses.
              <br />
            </span>
            <span className="text-wrapper-55">
              <br />
            </span>
            <span className="text-wrapper-55">
              <br />
              Getting There:
              <br />
              <br />
            </span>
            <span className="text-wrapper-55">
              Udaipur is well-connected by air, rail, and road. Maharana Pratap Airport (UDR) is the closest airport,
              while Udaipur City railway station offers connections to major cities in India.
              <br />
              With its captivating beauty, rich history, and vibrant culture, Udaipur promises an unforgettable travel
              experience. So, pack your bags and embark on a journey to the &#34;City of Lakes&#34;!
            </span>
          </p>
          <div className="frame-33">
            <div className="text-wrapper-56">More Similar Blogs</div>
            <div className="group-19">
              <div className="frame-34">
                <Link className="frame-35" to="/blog-page-inside-details">
                  <div className="overlap-group-16">
                    <div className="frame-36">
                      <img className="rectangle-6" alt="Rectangle" src="/img/rectangle-228-1.png" />
                    </div>
                    <div className="frame-37">
                      <p className="div-3">
                        <span className="text-wrapper-53">Community</span>
                        <span className="text-wrapper-57"> | June 6, 2024 </span>
                      </p>
                      <p className="unlocking-innovation-2">
                        Unlocking Innovation: Zomato’s journey to seamless iOS code sharing &amp; distribution with
                        Swift Package Manager
                      </p>
                    </div>
                  </div>
                </Link>
                <div className="frame-38">
                  <div className="overlap-group-16">
                    <div className="frame-36">
                      <img className="rectangle-6" alt="Rectangle" src="/img/rectangle-228-2.png" />
                    </div>
                    <div className="frame-37">
                      <p className="div-3">
                        <span className="text-wrapper-53">Reconnect</span>
                        <span className="text-wrapper-57"> | June 6, 2024</span>
                      </p>
                      <p className="unlocking-innovation-2">
                        Unlocking Innovation: Zomato’s journey to seamless iOS code sharing &amp; distribution with
                        Swift Package Manager
                      </p>
                    </div>
                  </div>
                </div>
                <div className="frame-39">
                  <div className="overlap-31">
                    <div className="frame-36">
                      <img className="rectangle-6" alt="Rectangle" src="/img/rectangle-228-3.png" />
                    </div>
                    <div className="frame-37">
                      <p className="div-3">
                        <span className="text-wrapper-53">Culture</span>
                        <span className="text-wrapper-57"> | June 6, 2024 </span>
                      </p>
                      <div className="text-wrapper-58">Unlocking Innovation: Zomato’s journey</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-40">
                <div className="frame-36">
                  <div className="overlap-group-16">
                    <div className="frame-36">
                      <img className="rectangle-6" alt="Rectangle" src="/img/rectangle-228-1.png" />
                    </div>
                    <div className="frame-37">
                      <p className="div-3">
                        <span className="text-wrapper-53">Inder Deep Singh</span>
                        <span className="text-wrapper-57"> | June 6, 2024 </span>
                      </p>
                      <p className="unlocking-innovation-2">
                        Unlocking Innovation: Zomato’s journey to seamless iOS code sharing &amp; distribution with
                        Swift Package Manager
                      </p>
                    </div>
                  </div>
                </div>
                <div className="frame-38">
                  <div className="overlap-group-16">
                    <div className="frame-36">
                      <img className="rectangle-6" alt="Rectangle" src="/img/rectangle-228-2.png" />
                    </div>
                    <div className="frame-37">
                      <p className="div-3">
                        <span className="text-wrapper-53">Inder Deep Singh</span>
                        <span className="text-wrapper-57"> | June 6, 2024</span>
                      </p>
                      <p className="unlocking-innovation-2">
                        Unlocking Innovation: Zomato’s journey to seamless iOS code sharing &amp; distribution with
                        Swift Package Manager
                      </p>
                    </div>
                  </div>
                </div>
                <div className="frame-39">
                  <div className="overlap-31">
                    <div className="frame-36">
                      <img className="rectangle-6" alt="Rectangle" src="/img/rectangle-228-3.png" />
                    </div>
                    <div className="frame-37">
                      <p className="div-3">
                        <span className="text-wrapper-53">Company</span>
                        <span className="text-wrapper-57"> | June 6, 2024 </span>
                      </p>
                      <div className="text-wrapper-58">Unlocking Innovation: Zomato’s journey</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img className="decor-24" alt="Decor" src="/img/decor-13-2.svg" />
          <img className="decor-25" alt="Decor" src="/img/decor-14-2.svg" />
          <div className="row-5">
            <div className="overlap-32">
              <div className="frame-41">
                <div className="text-wrapper-59">All Rights Reserverd@goEssy</div>
                <div className="list-4">
                  <div className="link-item-8">
                    <div className="symbol-10"></div>
                  </div>
                  <div className="link-item-9">
                    <div className="symbol-11"></div>
                  </div>
                  <div className="link-item-10">
                    <div className="symbol-12"></div>
                  </div>
                </div>
                <img
                  className="goessy-black-logo-4"
                  alt="Goessy black logo"
                  src="/img/goessy-black-logo-final-1-2.svg"
                />
              </div>
              <div className="frame-42">
                <div className="overlap-group-17">
                  <Link className="text-wrapper-60" to="/home-page">
                    Home
                  </Link>
                  <img className="line-9" alt="Line" src="/img/line-218-2.svg" />
                </div>
                <div className="overlap-33">
                  <div className="text-wrapper-61">Boutique Holidays</div>
                  <img className="line-9" alt="Line" src="/img/line-218-2.svg" />
                </div>
                <div className="overlap-34">
                  <Link className="text-wrapper-60" to="/lokally-ai">
                    Lokally AI
                  </Link>
                  <img className="line-9" alt="Line" src="/img/line-218-2.svg" />
                </div>
                <div className="overlap-35">
                  <Link className="text-wrapper-60" to="/about-us-page">
                    About Us
                  </Link>
                  <img className="line-10" alt="Line" src="/img/line-218-2.svg" />
                </div>
                <div className="overlap-36">
                  <Link className="text-wrapper-60" to="/blog-page">
                    Blog
                  </Link>
                  <img className="line-11" alt="Line" src="/img/line-218-2.svg" />
                </div>
                <div className="overlap-37">
                  <div className="text-wrapper-61">Terms and Policies</div>
                  <img className="line-10" alt="Line" src="/img/line-218-2.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-43">
          <div className="overlap-38">
            <div className="frame-44">
              <Link className="frame-45" to="/home-page">
                <div className="text-wrapper-62">Home</div>
              </Link>
              <div className="frame-46">
                <div className="text-wrapper-62">Boutique Holidays</div>
              </div>
              <div className="frame-47">
                <div className="text-wrapper-62">Lokally AI</div>
              </div>
              <Link className="frame-48" to="/about-us-page">
                <div className="text-wrapper-62">About Us</div>
              </Link>
              <Link className="frame-49" to="/blog-page">
                <div className="text-wrapper-62">Blogs</div>
              </Link>
            </div>
            <div className="rectangle-7" />
            <img className="goessy-logo-pdf-5" alt="Goessy logo pdf" src="/img/goessy-logo-pdf.png" />
          </div>
        </div>
      </div>
    </div>
  );
};
