import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { TermsAndConditions } from "./screens/TermsAndConditions";
import { HomePage } from "./screens/HomePage";
import { BlogPageInside } from "./screens/BlogPageInside";
import { LokallyAi } from "./screens/LokallyAi";
import { AboutUsPage } from "./screens/AboutUsPage";
import { BlogPage } from "./screens/BlogPage";
import { TermsAndConditionsScreen } from "./screens/TermsAndConditionsScreen";

const router = createBrowserRouter([
  {
    path: "/*",
    element: <TermsAndConditions />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />,
  },
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/blog-page-inside-details",
    element: <BlogPageInside />,
  },
  {
    path: "/lokally-ai",
    element: <LokallyAi />,
  },
  {
    path: "/about-us-page",
    element: <AboutUsPage />,
  },
  {
    path: "/blog-page",
    element: <BlogPage />,
  },
  {
    path: "/terms-and-conditions-1",
    element: <TermsAndConditionsScreen />,
  },
]);

export const App = () => {
  return <RouterProvider router={router} />;
};
