import React from "react";
import "./style.css";

export const TermsAndConditionsScreen = () => {
  return (
    <div className="terms-and-conditions-screen">
      <div className="terms-and-conditions-2">
        <img className="decor-55" alt="Decor" src="/img/decor.png" />
        <img className="decor-56" alt="Decor" src="/img/decor.png" />
        <div className="ellipse-54" />
        <div className="overlap-84">
          <img className="goessy-logo-pdf-12" alt="Goessy logo pdf" src="/img/goessy-logo-pdf.png" />
          <div className="text-wrapper-164">Home</div>
          <div className="text-wrapper-165">Boutique Holidays</div>
          <div className="text-wrapper-166">Lokally AI</div>
          <div className="text-wrapper-167">About Us</div>
          <div className="frame-170">
            <div className="overlap-85">
              <div className="overlap-group-36">
                <img className="decor-57" alt="Decor" src="/img/decor-2.svg" />
                <div className="group-44" />
                <img className="decor-58" alt="Decor" src="/img/decor-3.svg" />
                <img className="decor-59" alt="Decor" src="/img/decor-4.svg" />
                <div className="group-45">
                  <div className="ellipse-55" />
                  <div className="ellipse-56" />
                  <div className="ellipse-57" />
                </div>
              </div>
              <div className="group-46">
                <div className="ellipse-55" />
                <div className="ellipse-56" />
                <div className="ellipse-57" />
              </div>
            </div>
          </div>
          <div className="row-9">
            <div className="overlap-86">
              <div className="frame-171">
                <div className="text-wrapper-168">All Rights Reserverd@goEssy</div>
                <div className="list-11">
                  <div className="link-item-29">
                    <div className="symbol-31"></div>
                  </div>
                  <div className="link-item-30">
                    <div className="symbol-32"></div>
                  </div>
                  <div className="link-item-31">
                    <div className="symbol-33"></div>
                  </div>
                </div>
                <img
                  className="goessy-black-logo-11"
                  alt="Goessy black logo"
                  src="/img/goessy-black-logo-final-1-2-2.svg"
                />
              </div>
              <div className="frame-172">
                <div className="overlap-87">
                  <div className="text-wrapper-169">Home</div>
                  <img className="line-29" alt="Line" src="/img/line-218.svg" />
                </div>
                <div className="overlap-88">
                  <div className="text-wrapper-169">Boutique Holidays</div>
                  <img className="line-29" alt="Line" src="/img/line-218.svg" />
                </div>
                <div className="overlap-89">
                  <div className="text-wrapper-169">Lokally AI</div>
                  <img className="line-29" alt="Line" src="/img/line-218.svg" />
                </div>
                <div className="overlap-90">
                  <div className="text-wrapper-169">About Us</div>
                  <img className="line-30" alt="Line" src="/img/line-218.svg" />
                </div>
                <div className="overlap-91">
                  <div className="text-wrapper-169">Blog</div>
                  <img className="line-31" alt="Line" src="/img/line-218.svg" />
                </div>
                <div className="overlap-group-37">
                  <div className="text-wrapper-169">Terms and Policies</div>
                  <img className="line-30" alt="Line" src="/img/line-218.svg" />
                </div>
              </div>
            </div>
          </div>
          <div className="frame-173">
            <div className="text-wrapper-170">Blogs</div>
          </div>
          <div className="frame-174">
            <p className="privacy-policies-2">
              Privacy Policies
              <br />
              <br />
              1. Data Collection: We collect information, including but not limited to names, emails, and locations, to
              enhance user experiences and provide personalized services.
              <br />
               2. Data Usage: Collected data may be used for internal purposes, such as personalization and
              communication with users.
              <br />
               3. Data Security: We employ industry-standard security measures to protect user information from
              unauthorized access. 
              <br />
               ---
              <br />
              Shipping Policy 
              <br />
               <br />
              1. Delivery Time: Estimated delivery times vary based on user locations and travel plans. 
              <br />
              2. Shipping Fees: All applicable shipping fees are communicated clearly before purchase. 
              <br />
              3. Returns: Refer to our Refund Policy for information on returns.
              <br />
               ---
              <br />
               Data Security Policy:
              <br />
               1. Data Protection: We use industry-standard security measures to safeguard user information. 
              <br />
              2. Data Sharing: User information is not sold to third parties. 
              <br />
              --- 
              <br />
              Refund Policy
              <br />
               1. Eligibility: Refunds may be available for specific products or services, subject to conditions. 
              <br />
              2. Refund Process: Refund requests must adhere to specific guidelines and timeframes outlined in our
              Refund Policy.
              <br />
               ---
              <br />
               Wallet Policy:
              <br />
               1. Wallet Use: Users can utilize wallet balances for service payments, including bookings and purchases. 
              <br />
              2. No Deposits: Direct deposits into the wallet are not allowed. Wallet balances are generated through
              refunds after bank transfers.
              <br />
               3. Refunds to Wallet: Refunded amounts for services or purchases will be credited to the user&#39;s
              wallet balance. 
              <br />
              4. Transferring to Bank: Users can transfer funds from their wallets to their bank account at any time. 
              <br />
              --- 
              <br />
              Cancellation Policy
              <br />
               <br />
               1. Cancellation Process: To cancel bookings or services, follow these steps: 
              <br />
              Log in to your account.
              <br />
               Navigate to the &#34;Bookings&#34; or &#34;Services&#34; section. - Select the booking or service you
              wish to cancel.
              <br />
               Follow the on-screen instructions to complete the cancellation. 
              <br />
              2. Cancellation Fees: If applicable, cancellation fees may be charged based on the following conditions: 
              <br />
               Cancellations made 7 days or more before the scheduled date: The fee amount would be 80% of the Booking
              amount.
              <br />
               Cancellations made within 3 days of the scheduled date: The fee amount would be 50% of the Booking
              amount.
              <br />
              Cancellations made within 1 day before the scheduled date: The fee amount would be 100% of the Booking
              amount. Emergency or exceptional circumstances may be considered for fee waivers; contact our support team
              for assistance. 
              <br />
               ---
              <br />
              Cookie Policy
              <br />
               <br />
              1. Use of Cookies: We use cookies on our website and app to enhance your experience and provide
              personalized services. 
              <br />
              2. Opt-Out Options: You can opt-out of cookie tracking. For more details, refer to our privacy settings or
              cookie preferences.
              <br />
               ---
              <br />
              Terms of Service Updates:
              <br />
               <br />
               1. Notification of Changes: We will notify you of any changes to our terms and conditions via email or
              through in-app notifications. 
              <br />2 User Acknowledgment: By continuing to use our services, you acknowledge and accept any updates to
              the terms. 
              <br />
              --- 
              <br />
              User Reviews and Rating Policy: 
              <br />
               <br />
              1. Moderation: User-generated reviews and ratings are moderated to ensure content adheres to our
              guidelines. 
              <br />
              2. Dispute Resolution: If you have concerns about a review or rating, there is a dispute resolution
              process in place. Contact us for assistance.
              <br />
               --- 
              <br />
              Compliance with App Store Policies:
              <br />
               <br />
               1. App Store Regulations: Our app complies with the policies of the app stores it is available on,
              ensuring a secure and reliable experience. 
              <br />
              2. App Store Contact Information: For support related to app store transactions, please reach out to us
              at hello.goessy@gmail.com
              <br />
               --- 
              <br />
              Legal Jurisdiction
              <br />
               <br />
               1. Governing Law: All legal matters are subject to the laws of Ahmedabad, Gujrat. 
              <br />
              2. Dispute Resolution: In case of disputes, we follow a process of arbitration or mediation for fair and
              efficient resolution.
              <br />
               ---
            </p>
          </div>
        </div>
        <div className="ellipse-58" />
        <div className="frame-175">
          <div className="frame-176">
            <div className="text-wrapper-171">Terms and Conditions</div>
          </div>
          <div className="frame-177">
            <div className="text-wrapper-171">Privacy Policies</div>
          </div>
          <div className="frame-178">
            <div className="text-wrapper-171">Contact Us</div>
          </div>
        </div>
      </div>
    </div>
  );
};
