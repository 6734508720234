import React from "react";
import { Link } from "react-router-dom";
import { useWindowWidth } from "../../breakpoints";
import "./style.css";

export const BlogPage = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="blog-page">
      <div
        className="blog-page-mob"
        style={{
          height: screenWidth < 1920 ? "2858px" : screenWidth >= 1920 ? "4547px" : undefined,
          width: screenWidth < 1920 ? "374px" : screenWidth >= 1920 ? "1920px" : undefined,
        }}
      >
        <div
          className="overlap-66"
          style={{
            height: screenWidth >= 1920 ? "2463px" : screenWidth < 1920 ? "2955px" : undefined,
            left: screenWidth >= 1920 ? "0" : screenWidth < 1920 ? "-128px" : undefined,
            top: screenWidth >= 1920 ? "0" : screenWidth < 1920 ? "134px" : undefined,
            width: screenWidth >= 1920 ? "1920px" : screenWidth < 1920 ? "630px" : undefined,
          }}
        >
          <div
            className="frame-116"
            style={{
              height: screenWidth < 1920 ? "90px" : screenWidth >= 1920 ? "2463px" : undefined,
              left: screenWidth < 1920 ? "155px" : screenWidth >= 1920 ? "0" : undefined,
              top: screenWidth < 1920 ? "723px" : screenWidth >= 1920 ? "0" : undefined,
              width: screenWidth < 1920 ? "320px" : screenWidth >= 1920 ? "1920px" : undefined,
            }}
          >
            {screenWidth >= 1920 && (
              <>
                <div className="ellipse-44" />
                <div className="frame-117">
                  <Link className="frame-118" to="/blog-page-inside-details">
                    <div className="overlap-group-29">
                      <div className="frame-119">
                        <img className="rectangle-10" alt="Rectangle" src="/img/rectangle-228-1.png" />
                      </div>
                      <div className="frame-120">
                        <p className="community-june-2">
                          <span className="text-wrapper-132">Community</span>
                          <span className="text-wrapper-133">&nbsp;</span>
                          <span className="text-wrapper-134">| June 6, 2024 </span>
                        </p>
                        <p className="unlocking-innovation-3">
                          Unlocking Innovation: Zomato’s journey to seamless iOS code sharing &amp; distribution with
                          Swift Package Manager
                        </p>
                      </div>
                    </div>
                  </Link>
                  <div className="frame-121">
                    <div className="overlap-group-29">
                      <div className="frame-119">
                        <img className="rectangle-10" alt="Rectangle" src="/img/rectangle-228-2.png" />
                      </div>
                      <div className="frame-120">
                        <p className="div-5">
                          <span className="text-wrapper-135">Reconnect</span>
                          <span className="text-wrapper-136"> | June 6, 2024</span>
                        </p>
                        <p className="unlocking-innovation-3">
                          Unlocking Innovation: Zomato’s journey to seamless iOS code sharing &amp; distribution with
                          Swift Package Manager
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="frame-122">
                    <div className="overlap-67">
                      <div className="frame-119">
                        <img className="rectangle-10" alt="Rectangle" src="/img/rectangle-228-3.png" />
                      </div>
                      <div className="frame-120">
                        <p className="div-5">
                          <span className="text-wrapper-135">Culture</span>
                          <span className="text-wrapper-136"> | June 6, 2024 </span>
                        </p>
                        <div className="text-wrapper-137">Unlocking Innovation: Zomato’s journey</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-123">
                  <div className="frame-119">
                    <div className="overlap-group-29">
                      <div className="frame-119">
                        <img className="rectangle-10" alt="Rectangle" src="/img/rectangle-228-1.png" />
                      </div>
                      <div className="frame-120">
                        <p className="div-5">
                          <span className="text-wrapper-135">Inder Deep Singh</span>
                          <span className="text-wrapper-136"> | June 6, 2024 </span>
                        </p>
                        <p className="unlocking-innovation-3">
                          Unlocking Innovation: Zomato’s journey to seamless iOS code sharing &amp; distribution with
                          Swift Package Manager
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="frame-121">
                    <div className="overlap-group-29">
                      <div className="frame-119">
                        <img className="rectangle-10" alt="Rectangle" src="/img/rectangle-228-2.png" />
                      </div>
                      <div className="frame-120">
                        <p className="div-5">
                          <span className="text-wrapper-135">Inder Deep Singh</span>
                          <span className="text-wrapper-136"> | June 6, 2024</span>
                        </p>
                        <p className="unlocking-innovation-3">
                          Unlocking Innovation: Zomato’s journey to seamless iOS code sharing &amp; distribution with
                          Swift Package Manager
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="frame-122">
                    <div className="overlap-67">
                      <div className="frame-119">
                        <img className="rectangle-10" alt="Rectangle" src="/img/rectangle-228-3.png" />
                      </div>
                      <div className="frame-120">
                        <p className="div-5">
                          <span className="text-wrapper-135">Company</span>
                          <span className="text-wrapper-136"> | June 6, 2024 </span>
                        </p>
                        <div className="text-wrapper-137">Unlocking Innovation: Zomato’s journey</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-wrapper-138">Our Stories.</div>
                <p className="text-wrapper-139">
                  to worry about little things like Destination Research, What to pack, Itinerary planning, Local
                  Cuisine, Local Culture,
                </p>
                <div className="frame-124">
                  <div className="frame-125">
                    <div className="text-wrapper-140">All Categories</div>
                  </div>
                  <div className="frame-126">
                    <div className="text-wrapper-141">Community</div>
                  </div>
                  <div className="frame-127">
                    <div className="text-wrapper-142">Company</div>
                  </div>
                  <div className="frame-128">
                    <div className="text-wrapper-143">Company</div>
                  </div>
                  <div className="frame-129">
                    <div className="text-wrapper-144">Culture</div>
                  </div>
                  <div className="frame-130">
                    <div className="text-wrapper-145">Culture</div>
                  </div>
                </div>
                <div className="ellipse-45" />
                <img className="decor-44" alt="Decor" src="/img/decor-23-2.svg" />
                <img className="decor-45" alt="Decor" src="/img/decor-25.svg" />
                <img className="decor-46" alt="Decor" src="/img/decor-26.svg" />
              </>
            )}

            <div
              className="we-re-your"
              style={{
                color: screenWidth >= 1920 ? "var(--black)" : screenWidth < 1920 ? "#000000" : undefined,
                fontFamily:
                  screenWidth >= 1920
                    ? "'Poppins', Helvetica"
                    : screenWidth < 1920
                    ? "'Josefin Sans', Helvetica"
                    : undefined,
                fontSize: screenWidth >= 1920 ? "40px" : screenWidth < 1920 ? "20px" : undefined,
                height: screenWidth >= 1920 ? "60px" : screenWidth < 1920 ? "26px" : undefined,
                left: screenWidth >= 1920 ? "503px" : screenWidth < 1920 ? "96px" : undefined,
                letterSpacing: screenWidth >= 1920 ? "-1.60px" : screenWidth < 1920 ? "0" : undefined,
                lineHeight: screenWidth >= 1920 ? "60px" : screenWidth < 1920 ? "25.1px" : undefined,
                top: screenWidth >= 1920 ? "559px" : screenWidth < 1920 ? "-1px" : undefined,
              }}
            >
              {screenWidth >= 1920 && (
                <p className="text-wrapper-172"> We&#39;re your companions on the road to discovery.</p>
              )}

              {screenWidth < 1920 && <>All Categories</>}
            </div>
            {screenWidth >= 1920 && (
              <img className="goessy-logo-pdf-10" alt="Goessy logo pdf" src="/img/goessy-logo-pdf.png" />
            )}

            {screenWidth < 1920 && (
              <p className="text-wrapper-146">
                to worry about little things like Destination Research, What to pack, Itinerary planning, Local Cuisine,
                Local Culture,
              </p>
            )}
          </div>
          <div
            className="frame-131"
            style={{
              height: screenWidth >= 1920 ? "33px" : screenWidth < 1920 ? "196px" : undefined,
              left: screenWidth >= 1920 ? "1129px" : screenWidth < 1920 ? "140px" : undefined,
              top: screenWidth >= 1920 ? "47px" : screenWidth < 1920 ? "2489px" : undefined,
              width: screenWidth >= 1920 ? "698px" : screenWidth < 1920 ? "350px" : undefined,
            }}
          >
            {screenWidth >= 1920 && (
              <>
                <Link className="frame-132" to="/home-page">
                  <div className="text-wrapper-147">Home</div>
                </Link>
                <div className="frame-133">
                  <div className="text-wrapper-147">Boutique Holidays</div>
                </div>
                <div className="frame-134">
                  <div className="text-wrapper-147">Lokally AI</div>
                </div>
                <Link className="frame-135" to="/about-us-page">
                  <div className="text-wrapper-147">About Us</div>
                </Link>
                <div className="frame-136">
                  <div className="text-wrapper-147">Blogs</div>
                </div>
              </>
            )}

            {screenWidth < 1920 && (
              <div className="overlap-68">
                <div className="item-23">
                  <img
                    className="goessy-black-logo-9"
                    alt="Goessy black logo"
                    src="/img/goessy-black-logo-final-1-2-8.svg"
                  />
                  <div className="text-wrapper-148">All Rights Reserverd@goEssy</div>
                  <div className="list-9">
                    <div className="link-item-23">
                      <div className="symbol-25"></div>
                    </div>
                    <div className="link-item-24">
                      <div className="symbol-26"></div>
                    </div>
                    <div className="link-item-25">
                      <div className="symbol-27"></div>
                    </div>
                  </div>
                </div>
                <div className="frame-137">
                  <div className="frame-138">
                    <div className="frame-139">
                      <div className="overlap-group-30">
                        <div className="text-wrapper-149">Home</div>
                        <img className="line-22" alt="Line" src="/img/line-218-8.svg" />
                      </div>
                    </div>
                    <div className="frame-140">
                      <div className="overlap-69">
                        <div className="text-wrapper-149">Boutique Holidays</div>
                        <img className="line-22" alt="Line" src="/img/line-219-8.svg" />
                      </div>
                    </div>
                    <div className="frame-141">
                      <div className="overlap-70">
                        <div className="text-wrapper-149">Lokally AI</div>
                        <img className="line-22" alt="Line" src="/img/line-220-8.svg" />
                      </div>
                    </div>
                  </div>
                  <div className="frame-142">
                    <div className="frame-143">
                      <div className="overlap-group-31">
                        <div className="text-wrapper-149">About Us</div>
                        <img className="line-23" alt="Line" src="/img/line-221-3.svg" />
                      </div>
                    </div>
                    <div className="frame-144">
                      <div className="overlap-71">
                        <div className="text-wrapper-149">Blog</div>
                        <img className="line-22" alt="Line" src="/img/line-222-3.svg" />
                      </div>
                    </div>
                    <div className="frame-145">
                      <div className="overlap-72">
                        <div className="text-wrapper-149">Terms and Policies</div>
                        <img className="line-23" alt="Line" src="/img/line-223-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {screenWidth >= 1920 && <div className="text-wrapper-150">All Categories</div>}

          {screenWidth < 1920 && (
            <>
              <div className="frame-146">
                <div className="frame-147">
                  <div className="frame-148">
                    <img className="line-24" alt="Line" src="/img/line-228.svg" />
                    <div className="text-wrapper-151">Load More</div>
                  </div>
                </div>
              </div>
              <div className="group-41">
                <div className="text-wrapper-152">Our Stories.</div>
                <p className="we-re-your-2"> We&#39;re your companions on the road to discovery.</p>
              </div>
              <div className="ellipse-46" />
              <div className="ellipse-47" />
              <img className="decor-47" alt="Decor" src="/img/decor-46.svg" />
              <div className="group-42">
                <div className="overlap-73">
                  <img className="decor-48" alt="Decor" src="/img/decor-48.svg" />
                  <img className="decor-49" alt="Decor" src="/img/decor-49.svg" />
                </div>
                <img className="decor-50" alt="Decor" src="/img/decor-50.svg" />
                <div className="ellipse-48" />
                <img className="decor-51" alt="Decor" src="/img/decor-51.svg" />
                <div className="group-43">
                  <div className="ellipse-49" />
                  <div className="overlap-group-32">
                    <div className="ellipse-50" />
                    <div className="ellipse-51" />
                  </div>
                </div>
              </div>
              <img className="decor-52" alt="Decor" src="/img/decor-47.svg" />
              <div className="frame-149">
                <div className="frame-150">
                  <div className="text-wrapper-153">All Categories</div>
                </div>
                <div className="frame-151">
                  <div className="text-wrapper-154">Culture</div>
                </div>
                <div className="frame-152">
                  <div className="text-wrapper-155">Company</div>
                </div>
                <div className="frame-153">
                  <div className="text-wrapper-155">Company</div>
                </div>
                <div className="frame-154">
                  <div className="text-wrapper-154">Culture</div>
                </div>
                <div className="frame-155">
                  <div className="text-wrapper-154">Culture</div>
                </div>
              </div>
              <div className="frame-156">
                <div className="frame-157">
                  <div className="overlap-group-33">
                    <div className="frame-157">
                      <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-228-4.png" />
                    </div>
                    <div className="frame-158">
                      <p className="div-6">
                        <span className="text-wrapper-135">Company</span>
                        <span className="text-wrapper-156"> | June 6, 2024 </span>
                      </p>
                      <div className="text-wrapper-157">Unlocking Innovation: Zomato’s journey</div>
                    </div>
                  </div>
                </div>
                <div className="frame-159">
                  <div className="overlap-group-33">
                    <div className="frame-157">
                      <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-228-5.png" />
                    </div>
                    <div className="frame-158">
                      <p className="div-6">
                        <span className="text-wrapper-135">Company</span>
                        <span className="text-wrapper-156"> | June 6, 2024 </span>
                      </p>
                      <div className="text-wrapper-157">Unlocking Innovation: Zomato’s journey</div>
                    </div>
                  </div>
                </div>
                <div className="frame-160">
                  <div className="overlap-group-33">
                    <div className="frame-157">
                      <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-228-6.png" />
                    </div>
                    <div className="frame-158">
                      <p className="div-6">
                        <span className="text-wrapper-135">Company</span>
                        <span className="text-wrapper-156"> | June 6, 2024 </span>
                      </p>
                      <div className="text-wrapper-157">Unlocking Innovation: Zomato’s journey</div>
                    </div>
                  </div>
                </div>
                <div className="frame-161">
                  <div className="overlap-74">
                    <div className="rectangle-wrapper">
                      <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-228-7.png" />
                    </div>
                    <div className="frame-162">
                      <p className="div-6">
                        <span className="text-wrapper-135">Culture</span>
                        <span className="text-wrapper-156"> | June 6, 2024 </span>
                      </p>
                      <p className="text-wrapper-158">
                        Unlocking Innovation: Zomato’s journey Unlocking Innovation: Zomato s journey Unlocking
                        Innovation: Zomato’s journey
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {screenWidth < 1920 && (
          <div className="navbar-5">
            <a
              className="link-home-5"
              href="https://www.figma.com/design/TwClzvPbm6dQLNGBhf7ycp?node-id=2-2"
              rel="noopener noreferrer"
              target="_blank"
            >
              Home
            </a>
            <div className="link-our-5">Our Destinations</div>
            <a
              className="link-lokally-AI-5"
              href="https://www.figma.com/design/TwClzvPbm6dQLNGBhf7ycp?node-id=122-321"
              rel="noopener noreferrer"
              target="_blank"
            >
              Lokally AI
            </a>
            <div className="link-business-with-5">Business with us</div>
            <a
              className="link-about-us-5"
              href="https://www.figma.com/design/TwClzvPbm6dQLNGBhf7ycp?node-id=122-1271"
              rel="noopener noreferrer"
              target="_blank"
            >
              About Us
            </a>
            <div className="frame-163">
              <div className="overlap-group-34">
                <div className="rectangle-12" />
                <div className="text-wrapper-159">Menu</div>
                <img className="arrow-5" alt="Arrow" src="/img/arrow-1.svg" />
              </div>
            </div>
            <img className="goessy-logo-pdf-11" alt="Goessy logo pdf" src="/img/goessy-logo-pdf.png" />
          </div>
        )}

        {screenWidth >= 1920 && (
          <>
            <div className="overlap-75">
              <div className="frame-164">
                <Link className="frame-118" to="/blog-page-inside-details">
                  <div className="overlap-group-29">
                    <div className="frame-119">
                      <img className="rectangle-10" alt="Rectangle" src="/img/rectangle-228-1.png" />
                    </div>
                    <div className="frame-120">
                      <p className="div-5">
                        <span className="text-wrapper-135">Community</span>
                        <span className="text-wrapper-136"> | June 6, 2024 </span>
                      </p>
                      <p className="unlocking-innovation-3">
                        Unlocking Innovation: Zomato’s journey to seamless iOS code sharing &amp; distribution with
                        Swift Package Manager
                      </p>
                    </div>
                  </div>
                </Link>
                <div className="frame-121">
                  <div className="overlap-group-29">
                    <div className="frame-119">
                      <img className="rectangle-10" alt="Rectangle" src="/img/rectangle-228-2.png" />
                    </div>
                    <div className="frame-120">
                      <p className="div-5">
                        <span className="text-wrapper-135">Reconnect</span>
                        <span className="text-wrapper-136"> | June 6, 2024</span>
                      </p>
                      <p className="unlocking-innovation-3">
                        Unlocking Innovation: Zomato’s journey to seamless iOS code sharing &amp; distribution with
                        Swift Package Manager
                      </p>
                    </div>
                  </div>
                </div>
                <div className="frame-122">
                  <div className="overlap-67">
                    <div className="frame-119">
                      <img className="rectangle-10" alt="Rectangle" src="/img/rectangle-228-3.png" />
                    </div>
                    <div className="frame-120">
                      <p className="div-5">
                        <span className="text-wrapper-135">Culture</span>
                        <span className="text-wrapper-136"> | June 6, 2024 </span>
                      </p>
                      <div className="text-wrapper-137">Unlocking Innovation: Zomato’s journey</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ellipse-52" />
            </div>
            <div className="overlap-76">
              <div className="overlap-77">
                <div className="frame-165">
                  <div className="frame-119">
                    <div className="overlap-group-29">
                      <div className="frame-119">
                        <img className="rectangle-10" alt="Rectangle" src="/img/rectangle-228-1.png" />
                      </div>
                      <div className="frame-120">
                        <p className="div-5">
                          <span className="text-wrapper-135">Inder Deep Singh</span>
                          <span className="text-wrapper-136"> | June 6, 2024 </span>
                        </p>
                        <p className="unlocking-innovation-3">
                          Unlocking Innovation: Zomato’s journey to seamless iOS code sharing &amp; distribution with
                          Swift Package Manager
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="frame-121">
                    <div className="overlap-group-29">
                      <div className="frame-119">
                        <img className="rectangle-10" alt="Rectangle" src="/img/rectangle-228-2.png" />
                      </div>
                      <div className="frame-120">
                        <p className="div-5">
                          <span className="text-wrapper-135">Inder Deep Singh</span>
                          <span className="text-wrapper-136"> | June 6, 2024</span>
                        </p>
                        <p className="unlocking-innovation-3">
                          Unlocking Innovation: Zomato’s journey to seamless iOS code sharing &amp; distribution with
                          Swift Package Manager
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="frame-122">
                    <div className="overlap-67">
                      <div className="frame-119">
                        <img className="rectangle-10" alt="Rectangle" src="/img/rectangle-228-3.png" />
                      </div>
                      <div className="frame-120">
                        <p className="div-5">
                          <span className="text-wrapper-135">Company</span>
                          <span className="text-wrapper-136"> | June 6, 2024 </span>
                        </p>
                        <div className="text-wrapper-137">Unlocking Innovation: Zomato’s journey</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row-8">
                  <div className="overlap-78">
                    <div className="frame-166">
                      <div className="text-wrapper-160">All Rights Reserverd@goEssy</div>
                      <div className="list-10">
                        <div className="link-item-26">
                          <div className="symbol-28"></div>
                        </div>
                        <div className="link-item-27">
                          <div className="symbol-29"></div>
                        </div>
                        <div className="link-item-28">
                          <div className="symbol-30"></div>
                        </div>
                      </div>
                      <img
                        className="goessy-black-logo-10"
                        alt="Goessy black logo"
                        src="/img/goessy-black-logo-final-1-2-5.svg"
                      />
                    </div>
                    <div className="frame-167">
                      <div className="overlap-79">
                        <Link className="text-wrapper-161" to="/home-page">
                          Home
                        </Link>
                        <img className="line-25" alt="Line" src="/img/line-218-5.svg" />
                      </div>
                      <div className="overlap-80">
                        <div className="text-wrapper-162">Boutique Holidays</div>
                        <img className="line-25" alt="Line" src="/img/line-218-5.svg" />
                      </div>
                      <div className="overlap-81">
                        <Link className="text-wrapper-161" to="/lokally-ai">
                          Lokally AI
                        </Link>
                        <img className="line-25" alt="Line" src="/img/line-218-5.svg" />
                      </div>
                      <div className="overlap-group-35">
                        <Link className="text-wrapper-161" to="/about-us-page">
                          About Us
                        </Link>
                        <img className="line-26" alt="Line" src="/img/line-218-5.svg" />
                      </div>
                      <div className="overlap-82">
                        <div className="text-wrapper-162">Blog</div>
                        <img className="line-27" alt="Line" src="/img/line-218-5.svg" />
                      </div>
                      <div className="overlap-83">
                        <div className="text-wrapper-162">Terms and Policies</div>
                        <img className="line-26" alt="Line" src="/img/line-218-5.svg" />
                      </div>
                    </div>
                  </div>
                </div>
                <img className="decor-53" alt="Decor" src="/img/decor-22.svg" />
                <img className="decor-54" alt="Decor" src="/img/decor-24-2.svg" />
                <div className="ellipse-53" />
              </div>
              <div className="frame-168">
                <div className="frame-169">
                  <img className="line-28" alt="Line" src="/img/line-228-2.svg" />
                  <div className="text-wrapper-163">Load More</div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
